import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import { FaArrowCircleLeft } from "react-icons/fa";
import { UserContext } from "@contexts/UserContext";
import DatosVehiculo from "@views/Vehicle/components/DatosVehiculo/DatosVehiculo";
import NavVehiculos from "@views/Vehicle/components/NavVehiculo/NavVehiculo";
import InteresVehiculo from "@views/Vehicle/components/InteresVehiculo/InteresVehiculo";
import DocumentosVehiculo from "@views/Vehicle/components/DocumentosVehiculo/DocumentosVehiculo";
import PostventaVehiculo from "@views/Vehicle/components/PostventaVehiculo/PostventaVehiculo";
import VehiclePreparation from "@views/Vehicle/components/VehiclePreparation/VehiclePreparation";
import ModalLead from "@components/ModalLead";
import ModalCambioPosesion from "@components/ModalCambioPosesion";
import ModalRemoveVehicle from "@components/ModalRemoveVehicle";
import ModalRestoreVehicle from "@components/ModalRestoreVehicle";
import useSearchParam from "@hooks/useSearchParam";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import { putStatus } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";
import AdditionalInfoStock from "@views/Vehicle/components/AdditionalInfo/AdditionalInfo";
import ModalAddLeadInfo from "@components/ModalAddLeadInfo";
import CreateClient from "@components/CreateClientModal/CreateClientModal";
import { ModalClientsTable } from "@components/ModalClientsTable/ModalClientsTable";
import { checkWithin72Hours } from "@utils/checkWhitin72Hours";
import LeadTypes from "@constants/LeadTypes";
import RolType from "@constants/roles";


const Vehicle = () => {
  const { user, rol } = useContext(UserContext);
  const { t } = useTranslation("common");
  const { id: vehicleId } = useParams();
  const history = useHistory();

  const { getSearchParam } = useSearchParam();
  const tabSearchParam = getSearchParam("tab");

  const [vehicle, setVehicle] = useState({});
  const [authorized, setAuthorized] = useState(null);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);
  const [clientsByPhone,setClientsByPhone]= useState([])

  const [state, setState] = useState({
    vehicle: {},
    edit: false,
    estados: [],
    leads: null,
    totalLeads: 0,
    modal_check: false,
    modal_case: false,
    modal_add_client: false,
    modal_add_lead: false,
    modal_client_exist: false,
    modal_conflict: false,
    modal_slider: false,

    checkLead: {
      toggle: false,
      nombre: "",
      telefono: "",
      cliente: null,
      id_existente_lead: null,
      vehiculo: null,
      condiciones: false,
      comercial: false,
      provincia: "",
      municipio: "",
      estado: "",
      origen: "",
      forma_contacto: "",
      observaciones: "",
      email: "",
      telefono_secundario: "",
      apellido_1: "",
      apellido_2: "",
    },
  
    client: {
      contact: {}
    }
  });




  const handleSimpleInput = (e, type) => {
    setVehicle({ ...vehicle, [type]: e.target.value });
  };

  const toggleRemoveModal = () => {
    setRemoveOpen((prev) => !prev);
  };

  const toggleRestoreModal = () => {
    setRestoreOpen((prev) => !prev);
  };

  const removeVehicle = async () => {
    if (vehicle?.comment_status) {
      try {
        await putStatus(
          vehicle?.vehicle_id_flexicar,
          5,
          vehicle?.comment_status
        );
        toggleRemoveModal();
        setVehicle({ ...vehicle, vehicle_status: 5 });
      } catch (e) {
        errorToast(<span>{t("errors.errorVehicleRetire")}</span>);
      }
    } else {
      errorToast(<span>{t("errors.errorDischarge")}</span>);
    }
  };

  const restoreVehicle = async () => {
      try {
        await putStatus(
          vehicle?.vehicle_id_flexicar,
          1,
          vehicle?.comment_status.value
        );
        toggleRestoreModal();
        setVehicle({ ...vehicle, vehicle_status: 1 });
      } catch (e) {
        errorToast(<span>{t("errors.errorVehicleRetire")}</span>);
      }
    
  };


  const launchModal = (modal, vehicle_id) => {
    setState({
      ...state,
      [modal]: !state[modal],
      checkLead: {
        ...state.checkLead,
        vehiculo: vehicle_id,
        cliente: null,
      },
    });
  };

  const handleValues = (e, field, type) => {
    const val = e.target.value;
    setState({
      ...state,
      [type]: {
        ...state[type],
        [field]: val,
      },
    });
  };

  const onSelectClient = async (clienteId, sellLead) => {
		const { checkLead, vehicle } = state;
		const vehicleListId = checkLead.vehicle ? checkLead.vehicle : vehicle.id;
		const notMyProperty = sellLead?.agent?.id !== user;
		const isCallCenterSales = rol.includes(RolType.SALES_CALL_CENTER_AGENT);

		if (!sellLead) {
			setState({
				...state,
				modal_add_lead: true,
				modal_clients: false,
				checkLead: {
					...state.checkLead,
					clientId: clienteId
				}
			});
			return;
		}
		// Cliente tiene lead sobre este mismo vehiculo
		if (sellLead.vehicle?.id === vehicleListId) {
			// Comprobamos si es usuario Call center si no esta asignado y si esta dentro de las primeras 72 horas
			if (isCallCenterSales && notMyProperty && checkWithin72Hours(new Date(sellLead?.updatedAt))) {
				// Mostramos modal cambio de posesion
				setState({
					...state,
					checkLead: {
						...state.checkLead,
						cliente: clienteId,
						estado_anterior: sellLead.statusName,
						id_existente_lead: sellLead.id,
						nombre_agente: sellLead.agent.name
					},
					modal_conflict: true
				});
				return;
			}

			history.push(`/main/lead-venta/${sellLead.id}/${clienteId}`);
			return;
		}
		// Si el vehiculo es distinto al que seleccionamos, el usuario es call center ventas , no tenemos la propiedad del lead y esta dentro de las 72 horas
		if (
			sellLead.vehicle?.id !== vehicleListId &&
			isCallCenterSales &&
			notMyProperty &&
			checkWithin72Hours(new Date(sellLead.updatedAt))
		) {
			// Mostramos modal cambio de posesion
			setState({
				...state,
				checkLead: {
					...state.checkLead,
					cliente: clienteId,
					estado_anterior: sellLead.statusName,
					id_existente_lead: sellLead.id,
					nombre_agente: sellLead.agent.name
				},
				modal_aviso: true,
				modal_conflict: true
			});
			return;
		}

		setState({
			...state,
			modal_add_lead: true,
			modal_clients: false,
			checkLead: {
				...state.checkLead,
				clientId: clienteId
			}
		});
	};

  useEffect(() => {
    document.title = "Flexicar | Detalle vehículo";
    window.scrollTo(0, 0);
    (async () => {
      try {
        const vehicleBasic = await post(API.GET_VEHICLE, {
          vehicle_id: vehicleId,
        });
        setVehicle(vehicleBasic);
        setState((prev) => ({ ...prev, vehicle: vehicleBasic }));
      } catch (e) {
        errorCall(e);
      }
    })();
  }, [vehicleId]);

  //Comprobamos los permisos de usuario activar el acceso en STATE
  useEffect(() => {
    const asyncEffect = async () => {
      if (authorized === null && user && rol) {
        if (
          rol?.includes(1) ||
          rol?.includes(26) ||
          rol?.includes(18) ||
          rol?.includes(19) ||
          rol?.includes(37) 
        ) {
          setAuthorized(true);
        } else if (
          rol?.some((e) => e === 6 || e === 7 || e === 17 || e === 22)
        ) {
          try {
            const response = await post(API.USER_AUTH_CAR_BY_LOCAL, {
              vehicleId: vehicleId,
              userId: user,
            });
            response[0]?.authorized
              ? setAuthorized(true)
              : setAuthorized(false);
          } catch (e) {
            setAuthorized(false);
            errorCall(e);
          }
        } else {
          setAuthorized(false);
        }
      }
    };
    asyncEffect();
  }, [vehicleId, authorized, user, rol]);

  return (
    <div className="Cont mt-3">
      <>
        <NavVehiculos authorized={authorized} vehicle={vehicle}/>
        <TabContent className="pt-3" activeTab={tabSearchParam}>
          <TabPane tabId="datos">
            {tabSearchParam === "datos" && (
              <DatosVehiculo
                launchModal={launchModal}
                vehicle={vehicle}
                toggleRemoveModal={toggleRemoveModal}
                toggleRestoreModal={toggleRestoreModal}
              />
            )}
          </TabPane>
          <TabPane tabId="interes">
            {tabSearchParam === "interes" && (
              <InteresVehiculo vehicle={vehicle} launchModal={launchModal} />
            )}
          </TabPane>
          {authorized ? (
            <TabPane tabId="documentacion">
              {tabSearchParam === "documentacion" && (
								<DocumentosVehiculo
									vehicleId={vehicleId}
									state={state}
								/>
							)}
            </TabPane>
          ) : (
            <p>Sin autorización</p>
          )}
          <TabPane tabId="infoadicional">
            {tabSearchParam === "infoadicional" && (
              <AdditionalInfoStock vehicle={vehicle} />
            )}
          </TabPane>
          <TabPane tabId="preparacion">
            {tabSearchParam === "preparacion" && <VehiclePreparation vehicle={vehicle}  />}
          </TabPane>
          <TabPane tabId="postventa">
            {tabSearchParam === "postventa" && <PostventaVehiculo vehicle={vehicle} />}
          </TabPane>
        </TabContent>
      </>

      <div onClick={() => history.goBack()}>
        <FaArrowCircleLeft
          color="#007bff"
          size={40}
          role="button"
          className="bottom-fixed md-36 "
        />
      </div>

      <ModalLead
        state={state}
        launchModal={launchModal}
        handleValues={handleValues}
        setState={setState}
        setClientsByPhone={setClientsByPhone}
      />

      <CreateClient
				isOpen={state.modal_add_client}
				setIsOpen={() =>
					setState((prev) => ({
						...prev,
						modal_add_client: false,
					}))
				}
				phoneState={state.checkLead?.telefono}
				changeModeAddLead={(clientId) =>
					setState((prev) => ({
						...prev,
						modal_add_lead: true,
            checkLead: {
              ...prev.checkLead,
              clientId
            }
					}))
				}
				handleClose={() =>
					setState((prev) => ({
						...prev,
						modal_add_client: false
					}))
				}
        leadSell
			/>
			<ModalClientsTable
				isOpen={state.modal_clients}
				setIsOpen={() =>
					setState((prev) => ({
						...prev,
						modal_clients: false
					}))
				}
        onSelectClient={onSelectClient}
        onAddNewClient={() =>
					setState((prev) => ({
						...prev,
						modal_add_client: true
					}))
				}
				clientsInfo={clientsByPhone}
        leadType={LeadTypes.VENTA}
			/>
      <ModalAddLeadInfo
				isOpen={state.modal_add_lead}
				setIsOpen={() =>
					setState((prev) => ({
						...prev,
						modal_add_lead: false
					}))
				}
				state={state}
				setState={setState}
			/>
      <ModalCambioPosesion
        state={state}
        launchModal={launchModal}
        setState={setState}
      />

      {removeOpen ? (
        <ModalRemoveVehicle
          vehicle={vehicle}
          handleSimpleInput={handleSimpleInput}
          removeVehicle={removeVehicle}
          toggle={toggleRemoveModal}
          open={removeOpen}
        />
      ) : null}

      {restoreOpen ? (
        <ModalRestoreVehicle
          vehicle={vehicle}
          setVehicle={setVehicle}
          restoreVehicle={restoreVehicle}
          toggle={toggleRestoreModal}
          open={restoreOpen}
        />
      ) : null}
    </div>
  );
};

export default Vehicle;
