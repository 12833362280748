import { Button } from "reactstrap";
import { WarningPanel } from "@components/InfoBox";
import styles from "./index.module.scss";
import ModalCitar from "@views/NewLead/ModalCitar/ModalCitar";
import Checkbox from "@components/Checkbox/";
import Extras from "@views/NewLead/ExtrasCategory/ExtrasCategory";
import { errorToast } from '@helpers/toastFunctions';
import { post, postFile } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import React, { useState, useEffect, useContext, useMemo } from "react";
import EstadosVehiculo from "@views/NewLead/EstadosVehiculos/EstadosVehiculos";
import DatosVehiculoClasico from "@components/DatosVehiculosClasico";
import NewEmail from "@views/NewLead/ModalEnviarMail/ModalSendEmail";
import EstadosLead from "@views/NewLead/TableEstadosLeeds/LeadsStateTable";
import Conversaciones from "@views/NewLead/ModalConversacionLead/ModalConversacionLead";
import ModalBack from "@views/NewLead/ModalBack/ModalBack";
import { RiSave3Fill } from "react-icons/ri";
import { BsPrinterFill } from "react-icons/bs";
import { UserContext } from "@contexts/UserContext";
import { MdNoteAdd } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import Locale from "@constants/Locale";
import RolType from "@constants/roles";
import { IS_SUPER_ADMIN } from "@constants/roles";
import LEAD_STATUS from "@constants/LeadStatus";
import UploadCarousel from "@components/UploadCarousel";
import NoAutoCompleteInputSelect from "@components/NoAutoCompleteInputSelect";
import EditClient from "@views/LeadVenta/ModalEditClient/ModalClient";
import Select from "@components/Select";
import { getFilterPurchaseLeadState } from "@services/Filtros/filtros";
import DataCardVehicle from "@views/NewLead/DataCardVehicle/DataCardVehicle";
import revision from "@helpers/revision";
import instance from "@services/base";
import { VEHICLE_MODE } from "@constants/vehicleValidation";


const FormLead = ({
  plateState,
  launchModal,
  handleValues,
  getDataAdd,
  getData_CheckBox,
  createLead,
  state,
  setState,
  lead_id,
  vehiculo,
  setVehiculo,
  vehiculoOld,
  vehicleDataClient,
  estado,
  sendData,
  sendEstados,
  setModalPrintPercha,
  price,
  setRetailPrice,
  retailPrice,
  setPrice,
  mercedes,
  updateEstado,
  setLocalActual,
  localActual,
  getClient,
  clientData
}) => {

  const { user, rol, userType } = useContext(UserContext);

  const { i18n } = useTranslation();
  const { t } = useTranslation("fichaLeadsCompra");

  const [leadAgents, setLeadAgents] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadContactForms, setLeadContactForms] = useState([]);
  const [commercialBrands, setCommercialBrands] = useState([]);
  const [waitForBucketUpload, setWaitForBucketUpload] = useState(false);
  const [modalEditClient, setModalEditClient] = useState(false)

  const [leadStatusOptions, setLeadStatusOptions] = useState([]);

  const validFormats = ["png", "jpg", "jpeg", "webp"];

  const canEditPrices = IS_SUPER_ADMIN(userType) || rol.includes(RolType.CENTRAL_APPRAISER);

  const isNotTradingAgent = !rol?.some(
    (e) =>
      e === RolType.TRADING_AGENT 
  );

  const hasAccessToEarnedStatus = rol?.some(
    (e) =>
      e === RolType.GENERAL_ADMIN ||
      e === RolType.CENTRAL_APPRAISER ||
      e === RolType.TRADING_MANAGER
  );

  const getDealerships = async () => {
		const response = await instance.get("/api/locals?active=true");
		return response.data.map((dealership) => ({
			label: dealership.name,
			value: dealership.id
		}));
	};

  const noImg = useMemo(() => ({
    id: -1,
    url: `/locales/${i18n.language}/CarouselNoImg.svg`,
  }), [i18n]);
  const [images, setImages] = useState([noImg]);

  useEffect(() => {
    (async () => {
      const options = await getFilterPurchaseLeadState();
      setLeadStatusOptions(options);
    })();
  }, []);

  const buildLeadStatusConditionalOptions = (options) => {
    if (options) {
      if (hasAccessToEarnedStatus) {
        return options.filter((x) => (
          x.value !== LEAD_STATUS.formulario_nuevo
        ));
      } else {
        return options.filter((x) => (
          x.value !== LEAD_STATUS.formulario_nuevo &&
          x.value !== LEAD_STATUS.ganado
        ));
      };
    };
  };

  useEffect(() => {

    (async () => {
      if (lead_id) {
        const response = await post(API.GET_IMAGES, { lead_id });
        if (response && response.records.length > 0)
          setImages(
            response.records.map((record) => ({
              id: record.id,
              url: record.publicUrl,
            }))
          );
      }
    })();
  }, [lead_id, user]);

  useEffect(() => {
    const makePost = (query, data, setter) => {
      const dataCall = {
        data_query: query,
        data_call: data ? data : null,
      };
      post(API.FILTROS, { dataCall })
        .then((res) =>
          setter(
            res.map((x) => ({
              label: x.nombre,
              value: x.id,
            }))
          )
        )
        .catch((err) => errorToast(err));
    };

    if (user && userType) {
      if (state.edit_mode)
      makePost("agente_lead_compra", [userType, user, userType], setLeadAgents);
      makePost("origen_lead_compra", undefined, setLeadSources);
      makePost("contacto", undefined, setLeadContactForms);
      makePost("marcas_comerciales", user, setCommercialBrands);
    }
  }, [state.edit_mode, user, userType]);

  useEffect(() => {
    if (retailPrice !== "" || retailPrice !== null) {
      setRetailPrice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiculo]);



  useEffect(() => {
    if (images.length === 0) setImages([noImg]);
  }, [images, noImg]);


  const updateImgs = (newImgs) => {
    images[0].id === -1
      ? setImages(newImgs)
      : setImages((prev) => [...prev, ...newImgs]);
  };

  const validatePrice = (price) => {
		if (
			rol?.some((e) => e === RolType.CALL_CENTER || e === RolType.PURCHASING_CALL_CENTER_SUPERVISOR)
		)
			return true;

		if (!price === "" || price > 0) {
			return true;
		} else {
			errorToast(`${t("lookedPrice")} ${t("errors.errorNumMandatory")}`);
			return false;
		}
	};

  const validateVin = (bastidor) => {
      return revision(t, "bastidor", "bastidor", bastidor);
  }

  const validation = async () => {
		if (validatePrice(state.checkLead?.buscado) && validateVin(state.checkLead?.bastidor)) {
			state.edit_mode ? await createLead() : await createLead(handleUploadBucketImages);
		}
	};

  const handleImagesOnMemory = (files) => {
    const newImgs = files.map((file) => ({
      id: file.name,
      url: URL.createObjectURL(file),
      fileObject: file
    }));
    updateImgs(newImgs);
  };

  const deleteImageOnMemory = (imageId) =>
    setImages((prevImages) => prevImages.filter((x) => x.id !== imageId));

  const deleteAllImagesOnMemory = () => setImages([]);

  const handleUploadBucketImages = async (files, leadId) => {
    const formData = new FormData();
    formData.append("relativePath", leadId ?? lead_id);
    formData.append("token_name", "tasaciones_images");
    if (files !== undefined)
      files.map((file) => formData.append(file.name, file));
    else if (files === undefined && images[0].id !== -1)
      images.map(({ fileObject }) =>
        formData.append(fileObject.name, fileObject)
      );

    try {
      setWaitForBucketUpload(true);
      const uploadFilesMultiResp = await postFile(
        API.UPLOAD_FILES_MULTI,
        formData
      );
      if (uploadFilesMultiResp && uploadFilesMultiResp.res.length > 0) {
        try {
          const saveFilesDataResp = await post(API.SAVE_FILES_DATA, {
            id_lead: leadId ?? lead_id,
            data: uploadFilesMultiResp.res,
          });

          if (!saveFilesDataResp.error)
            updateImgs(
              uploadFilesMultiResp.res.map((file) => ({
                id: file.signUrlId,
                url: file.publicUrl,
              }))
            );
        } catch (e) {
          errorToast(<span>{t("errors.errorImage")}</span>);
        } finally {
          setWaitForBucketUpload(false);
        }
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorUploadImage")}</span>);
    }
  };

  const deleteBucketImage = async (imageId) => {
    await post(API.DELETE_IMAGES, { id: imageId });
    setImages((prevImgs) => prevImgs.filter((x) => x.id !== imageId));
  };

  const deleteAllBucketImagesForLead = async () => {
    await post(API.DELETE_ALL_IMAGES, { lead_id });
    setImages([]);
  };


  const checkPendingAppraisal = (filter, value) => {
    const preserveStatus = {
      ...state,
      checkLead: {
        ...state.checkLead,
        estado: state.checkLead.estado ? [...state.checkLead.estado] : []
      }
    }

    if (!localActual) {
      setState(preserveStatus)
      return errorToast(<span>{t("errors.errorLocal")}</span>);

    }
    else if (images[0].id === -1) {
      setState(preserveStatus)
      return errorToast(<span>{t("errors.errorPhotos")}</span>);

    }

    else {
      return updateEstado(filter, value)
    }
  }

  const sendAvailableStatus = (filter, value) => {
    if (
      value[0]?.value === LEAD_STATUS.pendiente_tasacion_central &&
      state.checkLead.estado?.[0]?.value !== LEAD_STATUS.pendiente_tasacion_central
    ) {
      checkPendingAppraisal(filter, value);
    } else {
      updateEstado(filter, value);
    }
  };
 
  const unlockedPriceInputs = images[0]?.id !== -1;

  return (
    <>
      {state.add_mode || state.edit_mode ? (
        <>
          <div>
            <div className={mercedes ? `${styles.mercedes}` : null}>
              <div
                className="clearfix mb-3"
                style={{
                  padding: ".7rem",
                  backgroundColor: "#2C2C2C",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                }}
              >
                <span className="tittle ml-4 mr-auto p-2">
                  {t('contactInfo')}
                </span>           
                {state.edit_mode ? (
                  <>
                    {i18n.language === Locale.ES &&                    
                      <NewEmail
                        nombre={clientData.name}
                        email={clientData.contact?.email}
                      />
                    } 
                   {clientData && (
											<EditClient
												leadType="purchase-leads"
												client={clientData}
												clientId={state.checkLead?.cliente}
												isOpen={modalEditClient}
												handleIsOpen={setModalEditClient}
												leadId={lead_id}
												getDataclient={getClient}
												isWebClient={clientData.isWebClient}
											/>
										)}              

                    <EstadosLead lead_id={lead_id} user={user} />
                    <Conversaciones lead_id={lead_id} user={user} />
                  </>
                ) : null}
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-12 ">
                  <div className={styles.floatingLabel}>
                    <input
                      id="Nombre"
                      placeholder={t('contactName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={clientData.name ?? ""}
                      disabled
                    />
                    <label for="Nombre">{t('contactName')}:</label>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      id="Apellido1"
                      placeholder={t('contactFirstName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={clientData.firstSurname ?? ""}
                      disabled
                    />
                    <label for="Apellido1">{t('contactFirstName')}:</label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('contactSecondName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={clientData.secondSurname ?? ""}
                      disabled
                    />
                    <label for="Apellido1">{t('contactSecondName')}:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('phone')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      disabled
                      value={clientData.contact?.primaryPhone ?? ""}
                    />
                    <label for="telefono">{t('phone')}:</label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('phone2')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={clientData.contact?.secondaryPhone ?? ""}
                      disabled
                    />
                    <label for="telefono">{t('phone2')}:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('email')}
                      type="text"
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      value={clientData.contact?.email ?? ""}
                      disabled
                    />
                    <label for="email">{t('email')}:</label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className={`col-md-3 col-sm-12`}>
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t("province")}
                      type="text"
                      value={clientData.address?.province?.name ?? ""}
                      disabled
                    />
                    <label for="Municipio">{t("province")}</label>
                  </div>
                </div>
                <div className="col-md-9 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t("municipality")}
                      type="text"
                      value={clientData.address?.municipality ?? ""}
                      disabled
                    />

                    <label for="Municipio">{t("municipality")}</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <Checkbox
                  key={"Condiciones"}
                  name={t('privatePolicy')}
                  status={state.checkLead.condiciones}
                  onChange={() =>
                    getData_CheckBox(
                      !state.checkLead.condiciones,
                      "condiciones"
                    )
                  }
                />
                <br />
                <Checkbox
                  key={"Comercial"}
                  name={t('communicationPolicy')}
                  status={state.checkLead.comercial}
                  onChange={() =>
                    getData_CheckBox(!state.checkLead.comercial, "comercial")
                  }
                />
              </div>
            </div>
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <span className="tittle ml-4">{t('leadData')}</span>
            </div>
            <div className="row">
              <div
                className={
                  state.edit_mode && !mercedes
                    ? `col-md-8 col-sm-12 `
                    : "col-md-12 col-sm-12"
                }
              >
                <div>
                  <div className="row" style={{ marginTop: ".25rem" }}>
                    <div
                      className={`col-md-12 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <div className={styles.filter}>
                        <label
                          className={
                            state.checkLead?.estado
                              ? styles.select
                              : styles.notSelect
                          }
                        >
                          {t("leadState")}
                        </label>
                        <Select
                          placeholder={t('leadState')}
                          options={buildLeadStatusConditionalOptions(leadStatusOptions)}
                          value={state.checkLead?.estado}
                          onChange={(opt) => sendAvailableStatus("estado", [opt])}
                        />
                      </div>
                    </div>
                    {state.edit_mode && userType ? (
                      <div
                        className={`col-md-12 col-sm-12 ${styles.floatingLabel}`}
                      >
                        <label
                          className={
                            state.checkLead?.agente
                              ? styles.select
                              : styles.notSelect
                          }
                        >
                          Agente:
                        </label>
                        <div className={styles.filter}>
                          <Select
                            options={leadAgents}
                            placeholder="Agente"
                            onChange={(newValue, action) =>
                              getDataAdd("agente", [newValue])
                            }
                            defaultValue={state.checkLead?.agente}
                            isDisabled={mercedes ? true : false}
                            components={{ Input: NoAutoCompleteInputSelect }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row" style={{ marginTop: ".25rem" }}>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.origen
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("leadSource")}
                      </label>
                      <div className={styles.filter}>
                        <Select
                          options={leadSources}
                          placeholder={t('leadSource')}
                          onChange={(newValue, action) =>
                            getDataAdd("origen", [newValue])
                          }
                          defaultValue={state.checkLead?.origen}
                          isDisabled={
                            state.edit_mode &&
                              state.checkLead?.forma_contacto &&
                              state.checkLead?.forma_contacto[0].value === 3
                              ? true
                              : false
                          }
                          components={{ Input: NoAutoCompleteInputSelect }}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.forma_contacto
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("leadContactForm")}:
                      </label>
                      <div className={styles.filter}>
                        <Select
                          options={leadContactForms}
                          placeholder={t("leadContactForm")}
                          onChange={(newValue, action) =>
                            getDataAdd("forma_contacto", [newValue])
                          }
                          defaultValue={state.checkLead?.forma_contacto}
                          isDisabled={
                            (state.edit_mode &&
                              state.checkLead?.forma_contacto &&
                              state.checkLead?.forma_contacto[0]?.value ===
                              3) ||
                              mercedes
                              ? true
                              : false
                          }
                          components={{ Input: NoAutoCompleteInputSelect }}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.local_actual
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("local")}:
                      </label>
                      <div className={styles.filter}>
                        <Select
                          placeholder={t('local')}
                          loadOptions={getDealerships}
                          value={localActual}
                          onChange={(opt) => setLocalActual([opt])}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.marca_comercial
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("commercialBrand")}:
                      </label>
                      <div className={styles.filter}>
                        <Select
                          options={commercialBrands}
                          placeholder={t("commercialBrand")}
                          onChange={(newValue, action) =>
                            getDataAdd("marca_comercial", [newValue])
                          }
                          defaultValue={state.checkLead?.marca_comercial}
                          isDisabled={
                            state.edit_mode &&
                            state.checkLead?.forma_contacto &&
                            state.checkLead?.forma_contacto[0]?.value === 3
                          }
                          components={{ Input: NoAutoCompleteInputSelect }}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                {state.edit_mode && !mercedes ? (
                  <ModalCitar
                    launchModal={launchModal}
                    getDataAdd={getDataAdd}
                    handleValues={handleValues}
                    state={state}
                    clientData={clientData}
                    setState={setState}
                    user={user}
                    className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
                    vehiculo={vehiculo}
                    createLead={createLead}
                    setLocalActual={setLocalActual}
                  />
                ) : null}
              </div>
            </div>

            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <span className="tittle ml-4">{t('vehicleData')}</span>
            </div>
            <DataCardVehicle vehicleDataClient={vehicleDataClient} vehiculo={vehiculo} setVehiculo={setVehiculo} vehiculoOld={vehiculoOld} />
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-8">
                <div>
                  <DatosVehiculoClasico
                    className={"none"}
                    vehiculo={vehiculo}
                    setVehiculo={setVehiculo}
                    type={'draft'}
                    mode={vehiculo.jato ? VEHICLE_MODE.JATO : VEHICLE_MODE.CLASSIC}
                  />
                  <div className="row pl-3 mt-2">
                    <div className="col-md-4 col-sm-12 pr-0">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('kms')}
                          className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                          type="number"
                          value={state.checkLead?.kilometros}
                          onChange={(e) =>
                            handleValues(e, "kilometros", "checkLead")
                          }
                        />
                        <label for="kilometros">{t('kms')}:</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 pr-0">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('vehiclePlate')}
                          className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                          type="text"
                          value={state.checkLead?.matricula ?? plateState}
                          onChange={(e) =>
                            handleValues(
                              {
                                ...e,
                                target: {
                                  ...e.target,
                                  value: e.target.value.toUpperCase(),
                                },
                              },
                              "matricula",
                              "checkLead"
                            )
                          }
                        />
                        <label for="matricula">{t('vehiclePlate')}:</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 pr-0">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('frame')}
                          className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                          type="text"
                          value={state.checkLead?.bastidor}
                          onChange={(e) =>
                            handleValues(e, "bastidor", "checkLead")
                          }
                        />
                        <label for="bastidor">{t('frame')}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <Checkbox
                      key={"Importado"}
                      name={t('imported')}
                      status={state.checkLead.importado}
                      onChange={() =>
                        getData_CheckBox(
                          !state.checkLead.importado,
                          "importado"
                        )

                      }
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 form-inline">
                    <label for="itv" style={{ fontSize: "13px" }}>
                      {t('itv')} {""}
                    </label>
                    <input
                      style={{ fontSize: "13px" }}
                      setter={"itv"}
                      id="itv"
                      placeholder={"Última ITV"}
                      className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                      type="date"
                      value={state.checkLead?.itv}
                      onChange={(e) =>
                        handleValues(e, "itv", "checkLead")
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  mercedes
                    ? `col-md-12 col-sm-12 col-lg-4 ${styles.order_first}`
                    : "col-md-12 col-sm-12 col-lg-4"
                }
              >
                {state.edit_mode ? (
                  <UploadCarousel
                    images={images}
                    uploadCb={(files) => handleUploadBucketImages(files, lead_id)}
                    deleteCb={deleteBucketImage}
                    deleteAllCb={deleteAllBucketImagesForLead}
                    validFormats={validFormats}
                  />
                ) : (
                  <UploadCarousel
                    images={images}
                    uploadCb={handleImagesOnMemory}
                    deleteCb={deleteImageOnMemory}
                    deleteAllCb={deleteAllImagesOnMemory}
                    validFormats={validFormats}
                  />
                )}
              </div>
            </div>
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <span className="tittle ml-4">{t('prices')}</span>
            </div>
            {!state.checkLead.captacion ? (
              <>
                <div className="row">
                  <div className="col-md-4 col-sm-12 align-self-center">
                    <p className="mb-1">
                      {t('onlineAppraisal')}:{" "}
                      <strong>
                        {state.checkLead?.tasacion_min &&
                          state.checkLead?.tasacion_min?.toLocaleString() +
                          " € - "}
                        {state.checkLead?.tasacion_max?.toLocaleString()}
                        {" €"}
                      </strong>
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12 align-self-center">
                    <p className="mb-1">
                      {t('priceNewVehicle')}:{" "}
                      <strong>
                        {retailPrice ? retailPrice : "-"} €
                      </strong>
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    {!canEditPrices && isNotTradingAgent ?
                      <p className="mb-1">
                        {t('lookedPrice')}:{" "}
                        <strong>
                          {state.checkLead?.buscado ? state.checkLead?.buscado : "-"} €
                        </strong>
                      </p> :
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('lookedPrice')}
                          className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                          type="number"
                          value={state.checkLead?.buscado}
                          onChange={(e) =>
                            handleValues(e, "buscado", "checkLead")
                          }
                        />
                        <label for="buscado">
                          {t('lookedPrice')}:
                        </label>
                      </div>}
                  </div>
                  <div className="col-12 d-flex">
                    <small>
                      {t('extrasAppraisal')}
                    </small>
                  </div>
                </div>
               {!unlockedPriceInputs && ( <div className="row">
                <div className="col-md-12 col-sm-12">
                  <WarningPanel className="mt-2" text={t('warningBlockPrice')}/>
                  </div>
                </div>)}
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div
                      className="clearfix mb-3"
                      style={{
                        padding: ".7rem",
                        backgroundColor: "#D1D1D1",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <span className="tittle ml-4 text-dark">{t('directPurchasePrice')}</span>
                    </div>
                    <div className="col-12 p-0">
                      {!canEditPrices ? <p className="mb-1 pb-2">
                        {t('preliminaryPrice')}:{" "}
                        <strong>
                          {state.checkLead?.precios.precio_preliminar_cd ? state.checkLead?.precios.precio_preliminar_cd : "-"} €
                        </strong>
                      </p> :
                        <div className={styles.floatingLabel}>
                          <input
                            placeholder={t('preliminaryPrice')}
                            className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                            type="number"
                            disabled={!unlockedPriceInputs}
                            value={state.checkLead?.precios.precio_preliminar_cd}
                            onChange={(e) =>
                              handleValues(e, "precio_preliminar_cd", "checkLead")
                            }
                          />
                          <label for="precio_preliminar_cd">{t('preliminaryPrice')}:</label>
                        </div>}
                    </div>
                    <div className="col-12 p-0">
                      {!canEditPrices ? <p className="mb-1">
                        {t('offeredPrice')}:{" "}
                        <strong>
                          {state.checkLead?.precios.precio_ofrecido_cd ? state.checkLead?.precios.precio_ofrecido_cd : "-"} €
                        </strong>
                      </p> :
                        <div className={styles.floatingLabel}>
                          <input
                            placeholder={t('offeredPrice')}
                            className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                            type="number"
                            disabled={!unlockedPriceInputs}
                            value={state.checkLead?.precios.precio_ofrecido_cd}
                            onChange={(e) =>
                              handleValues(e, "precio_ofrecido_cd", "checkLead")
                            }
                          />
                          <label for="precio_ofrecido_cd">{t('offeredPrice')}:</label>
                        </div>}
                    </div>
                  </div>
                  {i18n.language === Locale.ES && <div className="col-md-6 col-sm-12">
                    <div
                      className="clearfix mb-3"
                      style={{
                        padding: ".7rem",
                        backgroundColor: "#D1D1D1",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <span className="tittle ml-4 text-dark">{t('salesManagementPrice')}</span>
                    </div>
                    <div className="col-12 p-0">
                      {!canEditPrices ? <p className="mb-1 pb-2">
                        {t('preliminaryPrice')}:{" "}
                        <strong>
                          {state.checkLead?.precios.precio_preliminar_gdv ? state.checkLead?.precios.precio_preliminar_gdv : "-"} €
                        </strong>
                      </p> :
                        <div className={styles.floatingLabel}>
                          <input
                            placeholder={t('preliminaryPrice')}
                            className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                            type="number"
                            disabled={!unlockedPriceInputs}
                            value={state.checkLead?.precios.precio_preliminar_gdv}
                            onChange={(e) =>
                              handleValues(e, "precio_preliminar_gdv", "checkLead")
                            }
                          />
                          <label for="precio_preliminar_gdv">{t('preliminaryPrice')}:</label>
                        </div>}
                    </div>
                    <div className="col-12 p-0">
                      {!canEditPrices ? <p className="mb-1">
                        {t('offeredPrice')}:{" "}
                        <strong>
                          {state.checkLead?.precios.precio_ofrecido_gdv ? state.checkLead?.precios.precio_ofrecido_gdv : "-"} €
                        </strong>
                      </p> :
                        <div className={styles.floatingLabel}>
                          <input
                            placeholder={t('offeredPrice')}
                            className={`form-control ds-input mt-3 ${styles.floatingInput}`}
                            type="number"
                            disabled={!unlockedPriceInputs}
                            value={state.checkLead?.precios.precio_ofrecido_gdv}
                            onChange={(e) =>
                              handleValues(e, "precio_ofrecido_gdv", "checkLead")
                            }
                          />
                          <label for="precio_ofrecido_gdv">{t('offeredPrice')}:</label>
                        </div>}
                    </div>
                  </div>}
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className={styles.floatingLabel}>
                      <input
                        placeholder={"Precio pactado"}
                        className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                        type="text"
                        value={state.checkLead?.pactado}
                        onChange={(e) =>
                          handleValues(e, "pactado", "checkLead")
                        }
                      />
                      <label for="telefono">Precio pactado:</label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className={styles.floatingLabel}>
                      <input
                        placeholder={"Comision"}
                        className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                        type="text"
                        value={state.checkLead?.comision}
                        onChange={(e) =>
                          handleValues(e, "comision", "checkLead")
                        }
                      />
                      <label for="telefono">Comisión:</label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className={styles.floatingLabel}>
                      <input
                        placeholder={"Adelanto"}
                        className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                        type="text"
                        value={state.checkLead?.adelanto}
                        onChange={(e) =>
                          handleValues(e, "adelanto", "checkLead")
                        }
                      />
                      <label for="telefono">Adelanto:</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label for="itv">ITV {""}</label>
                    <input
                      setter={"itv"}
                      id="itv"
                      placeholder={"Última ITV"}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="date"
                      value={state.checkLead?.itv}
                      onChange={(e) =>
                        handleValues(e, "itv", "checkLead")
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {state.checkLead.captacion || state.checkLead.url_anuncio ? (
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={"Anuncio"}
                      disabled={!state.checkLead.captacion ? true : false}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={state.checkLead?.url_anuncio}
                      onChange={(e) =>
                        handleValues(e, "url_anuncio", "checkLead")
                      }
                    />
                    <label for="telefono">Anuncio:</label>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 align-items-center">
                  <Button
                    href={state.checkLead?.url_anuncio}
                    target="_blank"
                    className={"btn-flexicar"}
                    style={{ lineHeight: "2em" }}
                  >
                    Ir al anuncio
                  </Button>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <textarea
                  type="textarea"
                  placeholder={t("comments")}
                  className="form-control ds-input mt-4"
                  value={state.checkLead?.comentarios}
                  onChange={(e) =>
                    handleValues(e, "comentarios", "checkLead")
                  }
                />
              </div>
            </div>

            <div>
              {vehiculo?.jato ? (
                <Extras
                  disabled={!vehiculo.version ?? true}
                  //sendData={getDataAddMulti}
                  multi={true}
                  load={vehiculo?.version[0]?.value.length > 7 ? true : false}
                  query={
                    vehiculo.version && vehiculo?.version[0]
                      ? `${vehiculo.version[0].value}`
                      : null
                  }
                  className="form-control ds-input mt-1"
                  state={state}
                  setState={setState}
                  vehiculo={vehiculo}
                  setVehiculo={setVehiculo}
                  dontShowColapse={false}
                  sendData={sendData}
                  setRetailPrice={setRetailPrice}
                  retailPrice={retailPrice}
                  price={price}
                  setPrice={setPrice}
                />
              ) : null}
            </div>
            <div>
              {state.edit_mode ? (
                <EstadosVehiculo
                  lead_id={lead_id}
                  estado={estado}
                  sendEstados={sendEstados}
                />
              ) : null}
            </div>
            <div className={`m-3 row fixed-bottom ${styles.navMov}`}>
              <ModalBack createLead={createLead} />
              {state ? (
                <>
                  <Button
                    color="success"
                    className={styles.create}
                    disabled={waitForBucketUpload}
                    onClick={async () => {
                      validation()
                    }}
                  >
                    <RiSave3Fill size={30} role="button" />
                  </Button>

                  {state.edit_mode ? (
                    <>
                      <Button
                        color="warning"
                        className={mercedes ? styles.mercedes : styles.print}
                        onClick={() => setModalPrintPercha({ open: true })}
                      >
                        <BsPrinterFill size={30} />
                      </Button>

                      <Button
                        className={styles.contrato}
                        onClick={() => createLead()}
                      >
                        <MdNoteAdd />
                      </Button>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FormLead;
