import { Col, Row } from "reactstrap";
import styles from "./index.module.scss";
import { useMediaQuery } from "@hooks/useMediaQuery";
import Collapsible from "@components/Collapsible";

const BookingsCalendar = ({
	weekStart,
	getPreviousWeek,
	getNextWeek,
	weekDays,
	isAnyDealershipSelected,
	reservations,
	monthCount,
	totalBookingsText,
	totalDayBookingsText,
	showNoReservations,
	onDealershipClick,
	onBookingClick
}) => {
	const isDesktop = useMediaQuery("(min-width: 768px)");

	const DayHeader = ({ day, bookingsPerDay }) => (
		<div className={styles.calendarHeaderTitle}>
			{day}
			{bookingsPerDay}
		</div>
	);

	const BookingChip = ({ weekBookings, idx }) => (
		<>
			{isDesktop && (
				<DayHeader
					day={weekDays[idx]}
					bookingsPerDay={totalDayBookingsText(reservations.bookings[idx].length)}
				/>
			)}

			<div className={styles.startCol}>
				{weekBookings.map((booking) => (
					<div
						key={`bookings-${idx}-${booking.id}`}
						onClick={() => onBookingClick(booking.leadId, booking.clientId)}
						className={`${!showNoReservations ? "pointer" : ""} text-center ${
							styles.calendarBlock
						} ${styles[`${booking.state}`]} `}
					>
						{booking.title}
					</div>
				))}
			</div>
		</>
	);

	const DealershipChip = ({ weekDealerships, idx }) => (
		<>
			{isDesktop && (
				<DayHeader
					day={weekDays[idx]}
					bookingsPerDay={totalDayBookingsText(reservations.dealerships[idx].length)}
				/>
			)}

			<div className={styles.startCol}>
				{weekDealerships.map((dealership) => (
					<div
						key={`dealerships-${idx}-${dealership.value}`}
						onClick={() => onDealershipClick(dealership.value, dealership.label)}
						className={`${!showNoReservations ? "pointer" : ""} text-center ${
							styles.calendarBlock
						} ${!showNoReservations ? styles.pagado : styles.no_reservations}`}
					>
						{dealership.label} ({dealership.totalBookings})
					</div>
				))}
			</div>
		</>
	);

	return (
		<>
			<div className={styles.titleCalendar}>
				<div className={styles.flexBetween}>
					<div className={`${styles.fleft} ${styles.calendarMounth}`}>
						<span className={styles.capitalize}> {weekStart.format("MMMM")} </span>
						<span>{weekStart.year()}</span>
						<span>{` - ${monthCount}`}</span>
					</div>
					<div className={styles.buttonsCalendar}>
						<div className={styles.backgroundArrows}>
							<span
								className={styles.calendarArrows}
								onClick={getPreviousWeek}
							>
								{"<"}
							</span>
						</div>
						<div className={styles.backgroundArrows}>
							<span
								className={styles.calendarArrows}
								onClick={getNextWeek}
							>
								{">"}
							</span>
						</div>
					</div>
				</div>
				{totalBookingsText}
			</div>

			<Row className={`${styles.startRow} ml-0 text-center ${styles.calendarHeader}`}>
				{isAnyDealershipSelected
					? reservations.bookings.map((weekBookings, idx) => (
							<Col
								key={`bookings-${idx}`}
								className={`col-12 col-md ${styles.calendarContent}`}
							>
								{isDesktop ? (
									<BookingChip
										weekBookings={weekBookings}
										idx={idx}
									/>
								) : (
									<Collapsible
										header={
											<>
												{weekDays[idx]}
												{totalDayBookingsText(reservations.dealerships[idx].length)}
											</>
										}
										className={styles.calendarHeaderTitle}
									>
										<BookingChip
											weekBookings={weekBookings}
											idx={idx}
										/>
									</Collapsible>
								)}
							</Col>
					  ))
					: reservations.dealerships.map((weekDealerships, idx) => (
							<Col
								key={`dealerships-${idx}`}
								className={`col-12 col-md ${styles.calendarContent}`}
							>
								{isDesktop ? (
									<DealershipChip
										weekDealerships={weekDealerships}
										idx={idx}
									/>
								) : (
									<Collapsible
										header={
											<>
												{weekDays[idx]}
												{totalDayBookingsText(reservations.dealerships[idx].length)}
											</>
										}
										className={styles.calendarHeaderTitle}
									>
										<DealershipChip
											weekDealerships={weekDealerships}
											idx={idx}
										/>
									</Collapsible>
								)}
							</Col>
					  ))}
			</Row>
		</>
	);
};

export default BookingsCalendar;
