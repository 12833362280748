import * as Yup from "yup";

const FILE_MAX_SIZE_MB = 10;
const FILE_MAX_SIZE = FILE_MAX_SIZE_MB * 1024 * 1024;
const FILE_ACCEPTED_FORMATS = [
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"image/jpeg",
	"image/png",
	"image/webp"
];

const uploadDocumentationSchema = Yup.object({
	docName: Yup.string().required("Campo obligatorio"),
	docType: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	file: Yup.mixed()
		.required("Archivo obligatorio")
		.test(
			"fileType",
			"Formato no permitido",
			(file) => file && FILE_ACCEPTED_FORMATS.includes(file.type)
		)
		.test(
			"fileSize",
			`Tamaño máximo ${FILE_MAX_SIZE_MB} MB`,
			(file) => file && file.size <= FILE_MAX_SIZE
		)
});

export { uploadDocumentationSchema };
