export const API = {
  FILTROS: "/api/filtros",
  CHECK_LEAD: "/api/check_lead",
  CREATE: "/api/sell-leads",
  SAVE_AGENT_POSSESSION: "/api/save_agent_possession",
  SEARCH_CARS: "/api/search_cars",
  GET_VEHICLE: "/api/get_vehicle",
  GET_VEHICLE_LEADS: "/api/get_vehicle_leads",
  GET_VEHICLE_STATICS: "/api/get_vehicle_statics",
  GET_ENLACES: "/api/get_enlaces",
  GET_ESTADOS: "/api/get_estados",
  CHECK_PAGE_PERMISSION: "/api/check_page_permission",
  GET_VENTAS_AGENTS: "/api/get_ventas_agents",
  CHANGE_AGENT_SHIFT: "/api/change_agent_shift",
  CHANGE_AGENT_HOLIDAYS: "/api/change_agent_holidays",
  CHANGE_AGENT_LEAVE: "/api/change_agent_leave",
  UPDATE_AGENT_TO_VENTAS: "/api/update_agent_to_ventas",
  MOVE_AGENT_TO_VENTAS: "/api/move_agent_to_ventas",
  GET_AV_MARCA_IN_STOCK: "/api/get_av_marcaInStock",
  GET_AV_LOCAL_IN_STOCK: "/api/get_av_localInStock",
  GET_VEHICLE_FOTOS: "/api/get_vehicle_fotos",
  EQUIPAMIENTO: "/api/equipamiento",
  CARGAR_MENSAJES_VO: "/api/cargar_mensajes_vo",
  GET_AGENT_TO_ADD_TO_VENTAS: "/api/get_agent_to_add_to_ventas",
  GET_AV_DAYS_IN_STOCK: "/api/get_av_daysInStock",
  GET_DOCUMENTACION_FIRMAS: "/api/get_documentacion_firmas",
  USER_AUTH_CAR_BY_LOCAL: "/api/user_auth_car_by_local",
  CHANGE_PROMO_STATE: "/api/change_promo_state",
  CREATE_CASE: "/api/create_case",
  GET_CASE: (id) => `/api/post-venta/cases/${id}`,
  ADD_COMMENT: "/api/add_comment",
  UPDATE_COMMENT: (id) => `/api/post-venta/comments/${id}`,
  CREATE_DOCUMENT_POST_VENTA: (id) => `/api/post-venta/cases/${id}/documents`,
  GET_VEHICLE_CASES_AMOUNT: (id) => `/api/post-venta/${id}/totalAmount`,
  GET_VEHICLE_DRAFT_POST_VENTA: (id)=> `/api/post-venta/cases/${id}/documents-draft`,
  GET_VEHICLE_POST_VENTA: (id,documentId)=> `/api/post-venta/cases/${id}/documents/${documentId}`,
  GET_DOCUMENTS_POST_VENTA: (id,size,page) => `/api/post-venta/cases/${id}/documents?size=${size}&page=${page}`,
  DELETE_DOCUMENT_POST_VENTA: (id,documentId)=> `/api/post-venta/cases/${id}/documents/${documentId}`,
  EDIT_DOCUMENT_POST_VENTA: (id,documentId)=> `/api/post-venta/cases/${id}/documents/${documentId}`,
  GET_DOCUMENT_POST_VENTA_SIGN:(documentId)=> `/api/post-venta/documents/${documentId}/file`,
  GET_CASES: "/api/get_cases",
  GET_COMMENTS: "/api/get_comments",
  GET_GARANTIA:"/api/get_garantia",
  ADD_CAR_DELIVERY_DATE: "/api/add_car_delivery_date",
  ADD_CAR_BILL_DATE: "/api/add_car_bill_date",
  REBU_BANCO: "/api/rebu_banco",
	GET_UPLOAD_FILES_DOCUMENT_TYPES: (idContractType) =>
		`/api/documents-types${
			idContractType !== undefined ? `?idContractType=${idContractType}` : ""
		}`,
  UPLOAD_FILES: "/api/upload_files",
  DELETE_FILES: "/api/delete_files",
  CHANGE_STATE_CASE: "/api/change_state_case",
  GET_CASES_BY_USER: "/api/get_cases_by_user",
  GET_DOCUMENTACION_FIRMAS_BY_LOCALES:
    "/api/get_documentacion_firmas_by_locales",
  BOOKING_CALENDAR: "/api/booking_calendar",
  DELIVERED_CALENDAR: "/api/delivered_calendar",
  GET_USER_LOCALS: "/api/get_user_local",
  UPDATE_CONTRACT: "/api/update_contract",
  UPDATE_SALE_CONTRACT: (id) => `/api/sale-contract/${id}`,
  
  GET_EMPRESA_BY_CIF: "/api/get_empresa_by_cif",
  GET_EMPRESA_BY_ID: "/api/get_empresa_by_id",
  GET_EMPRESAS_BY_LOCAL: "/api/get_empresas_by_local",
  GET_PROVINCIA_LOCAL: "/api/get_provincia_local",
  GET_HISTORICO_LEAD: "/api/get_historico_lead",
  GET_MENSAJES_LEAD: "/api/get_mensajes_lead",
  SAVE_MENSAJE_LEAD: "/api/save_mensaje_lead",
  GET_PROVINCIAS: "/api/get_provincias",
  GET_LOCAL_NAME_BY_ID: "/api/get_local_name_by_id",
  DELETE_DOCUMENT: "/api/delete_document",
  DIGITAL_SIGN: "/api/digital_sign",
  SEND_EMAIL_CITA: "/api/send_mail_cita_tasacion",
  SEND_EMAIL_MESSAGE: "/api/send_mail_message",
  SEND_EMAIL_MESSAGE_VENTA: "/api/send_mail_message_venta",
  SEND_APPOINTMENT_SALES_INTERNAL: "/api/send_appointment_sales_internal",
  SEND_APPOINTMENT_SALES_EXTERNAL: "/api/send_appointment_sales_external",
  CREATE_INVOICE: "/api/create_invoice",
  CREATE_CONTRACT_VENTA: "/api/create_contract_venta",
  GET_LAST_INVOICE: "/api/get_last_invoice",
  CHANGE_GARANTIA: "/api/change_garantia",

  DELETE_SIGN_DATE: "/api/delete_sign_date",
  GET_LAST_LEAD_STATUSID: "/api/getlaststatusid",
  CHECK_EXIST: "/api/check_exist",
  CREATE_LEAD_TASACION: "/api/create_lead_compra",
  GET_LEAD: "/api/get_lead",
  NEW_APPOINTMENT: "/api/new_appointment",
  DELETE_APPOINTMENT: "/api/delete_appointment",
  GET_ESTADOS_LEEDS: "/api/get_estados_leeds",
  GET_CONVERSATIONS_LEEDS: "/api/get_conversations_leeds",
  NEW_CONVERSATION_LEED: "/api/new_conversation",
  GET_TASACIONES: "/api/get_tasaciones_previas",
  GET_ESTADO_VEHICULO: "/api/get_estado_vehiculo",
  UPDATE_ESTADO: "/api/update_estado",
  UPDATE_PRINT: "/api/update_print",

  UPLOAD_FILES_MULTI: "/api/upload_files_multi",
  SAVE_FILES_DATA: "/api/save_files_data",
  GET_IMAGES: "/api/get_images",
  DELETE_IMAGES: "/api/delete_images",
  DELETE_ALL_IMAGES: "/api/delete_all_images",
  GET_SELL_LEADS: "/api/sellleadslist",
  GET_SELL_LEADS_COUNT: "/api/sellleadslistcount",
  SELL_LEADS_ASSIGN: "/api/sell-leads/assign",
  SELL_LEADS_ASSIGNABILITY: "/api/sell-leads/assignability",
  GET_BUY_LEADS: "/api/buyleadslist",
  GET_PURCHASE_LEADS_BY_CLIENT: (id,page)=> `api/purchase-leads/clients/${id}?page=${page}`,
  GET_BUY_LEADS_COUNT: "/api/buyleadslistcount",
  GET_CLIENT_LEAD_VENTA: "/api/clientleadventa",
  UPDATE_LEAD_VENTA_STATUS: "/api/updateleadventastatus",
  DELETE_APPOINTMENT_VENTA: "/api/delete_appointment_venta",
  NEW_APPOINTMENT_VENTA: "/api/new_appointment_venta",
  GET_LEAD_VENTA: "/api/leadventa",
  CHANGE_LEAD_MANAGER: "/api/changeleadmanager",
  CHANGE_LEAD_VENTA_MANAGER: "/api/changeleadventamanager",
  CHANGE_LEAD_STATUS: "/api/changeleadstatus",
  CHANGE_LEAD_VENTA_STATUS: "/api/changeleadventastatus",
  INSERT_LEAD_STATUS: "/api/insertleadstatus",
  GET_VEHICLE_BY_PLATE: "/api/getVehicleByPlate",
  GET_CLIENT_BY_DNI: "/api/getClientByDNI",
  GET_CLIENTS_QUERY:"/api/lead-clients",
  GET_USER_ID_TIPO: "/api/getuseridtipo",
  CREATE_CONTRACT: "/api/createContract",
  GET_LOCAL_PHONE: "/api/getLocalPhoneNumber",
  GET_CONTRACT: "/api/getContract",
  GET_CONTRACT_COMPRA: "/api/getContractCompra",
  GET_COCHE_PAGO: "/api/getCochePago",
  GET_LEED_BY_PLATE: "/api/getLeedPlate",
  CHECK_CLIENTE_UPDATE: "/api/check_client_update",
  VERIFICATION_CLIENT_DATA:(client_id)=>`/api/clients/${client_id}/verification`,
  LINK_LEAD_CLIENT:(lead_type,lead_id,client_id)=>`/api/${lead_type}/${lead_id}/clients/${client_id}/link`,
  UPDATE_CLIENT_DATA: (client_id) => `api/lead-clients/${client_id}`,
  CREATE_CLIENT:"api/lead-clients",
  GET_CLIENT:(client_id)=> `api/lead-clients/${client_id}`,
  JATO: "/api/jato",
  LOAD_MMA_QUOTATION: "/api/loadMMAquotation",
  SAVE_MMA_QUOTATION: "/api/saveMMAquotation",
  UPDATE_MMA_QUOTATION: "/api/sell-leads",
  LAUNCH_MMA_QUOTATION: "/api/launchMMAquotation",
  MMA_QUOTATION:"/api/mmaQuotation",
  CHECK_LEAD_POSSESSION: "/api/checkLeadPossession",
  SAVE_CHANGE_POSSESSION: "/api/saveChangePossession",
  CHANGE_AGENT_VENTAS: "/api/leads",
  GET_EMPLOYEE_GREATER_TYPE: "/api/employee/greater-type/names",
  GET_AGENTS: "/api/employee/with-agent-state",
  EMPLOYEE_PROFILE: "/api/employee/profile",
  EMPLOYEE_CONTRACTS: "/api/employee/contracts",

  CREATE_DESTACADO: "/api/create_destacado",
  UPDATE_DESTACADO: "/api/update_destacado",
  GET_OTHER_CLIENT_LEADS: "/api/get_other_client_leads",
  GET_RESERVA: "/api/get_reserva",
  GET_CONTRACT_LEAD: "/api/get_contract",
  CANCEL_RESERVA: "/api/cancelReservation",
  CREATE_RESERVATION_DOCUMENT: "/api/createReservationDocument",
  CREATE_RESERVATION: "/api/createReservation",
  GET_TPV_VIRTUAL: "/api/getTPVvirtual",
  GET_WARRANTIES_BY_VEHICLE: (id) => `/api/vehicles/${id}/available-warranties`,

  ADMIN_TASK_CREATE:(id) => `api/management/tasks/contracts/${id}`,
  CREATE_CONTRACT_BY_GDV: (id)=>`api/sell-management-contract/${id}/close`,
  
  PAGOFACIL_CALL: "/api/pagofacilCall",

  DOCGEN_CONTRACT: "/api/docgen-contract",

  GOOGLE_TRANSLATE:(apiKey)=> `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
};
