import { useState } from "react";

import SellContractPT from "@views/SellContractPT/SellContractPT";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { getFilterDealershipsLocals, getFilterProvinces } from "@services/Filtros/filtros";
import { getWarrantiesOptions } from "@services/Warranties/warranties";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { paymentTypePT, yesOrNotSelectPT, transportPricesPT } from "@constants/selectOptionsPT";
import DocumentationType from "@constants/documentationType";

const EditSellContractPT = ({
	document: { datos: data, documentId, vehicle_id_stock, id_vehiculo },
	onEdit
}) => {
	const [dealershipsOptions, setDealershipsOptions] = useState([]);
	const [provincesOptions, setProvincesOptions] = useState([]);
	const [warrantyOptions, setwarrantyOptions] = useState([]);

	const vehicleId = id_vehiculo;

	const loadDefaultValues = async () => {
		const [dealerships, provinces, warranties] = await Promise.all([
			getFilterDealershipsLocals(),
			getFilterProvinces(),
			getWarrantiesOptions(vehicleId)
		]);

		setDealershipsOptions(dealerships);
		setProvincesOptions(provinces);
		setwarrantyOptions(warranties);

		const findValue = (arr, findValue) => arr.find((x) => x.value === findValue);

		const carPricePercentage = (data.pvp * 10) / 100;

		const clientDefault = {
			name: data.nombre,
			firstSurname: data.apellido1,
			secondSurname: data.apellido2,
			dni: data.DNI,
			phone: data.telefono,
			email: data.email,
			province: { label: data.provincia, value: data.id_provincia },
			town: data.poblacion,
			address: data.direccion,
			cp: data.CP
		};

		const vehicleDefault = {
			brand: data.marca,
			model: data.modelo,
			version: data.version,
			plate: data.matricula,
			plateDate: data.plateDate,
			km: data.kilometros,
			bastidor: data.bastidor,
			color: data.color,
			fuel: data.fuel,
			transmission: data.transmission,
			dealership: { label: data.local, value: data.id_local },
			dealershipProvince: {
				label: data.provincia_local.label,
				value: data.id_provincia_local.value
			},
			conformidades: data.conformidades || null
		};

		const paymentDefault = {
			type: findValue(paymentTypePT, data.paymentTypeId),
			reservation: {
				wants: findValue(yesOrNotSelectPT, data.reservation),
				amount: data.reservationAmount
			},
			entry: {
				wants: findValue(yesOrNotSelectPT, data.entry),
				amount: data.entryAmount
			}
		};

		const sellInfoDefault = {
			priceType: data.priceType,
			administrativeCosts: data.administrativeCosts,
			pvp: data.pvp,
			transport: {
				wants: findValue(yesOrNotSelectPT, data.transporte),
				amount: data.importe_transporte,
				type: findValue(transportPricesPT(carPricePercentage), data.transportType),
				expectedDeliveryDate: data.expectedDeliveryDate || ""
			},
			warranty: {
				type: findValue(warranties, data?.warranty?.typeId),
				amount: data.warranty?.price
			},
			carAsPayment: {
				wants: findValue(yesOrNotSelectPT, data.carAsPayment),
				amount: data.pago_coche_forma_pago,
				vehicle: data.carAsPaymentVehicle
					? {
							brand: {
								label: data.carAsPaymentVehicle.brand,
								value: Number(data.carAsPaymentVehicle.brandId)
							},
							model: {
								label: data.carAsPaymentVehicle.model,
								value: Number(data.carAsPaymentVehicle.modelId)
							},
							version: data.carAsPaymentVehicle.version,
							plate: data.carAsPaymentVehicle.plate,
							plateDate: data.carAsPaymentVehicle.plateDate,
							vin: data.carAsPaymentVehicle.vin,
							kms: data.carAsPaymentVehicle.kms,
							color: data.carAsPaymentVehicle.color,
							fuel: {
								label: data.carAsPaymentVehicle.fuel,
								value: data.carAsPaymentVehicle.fuelId
							},
							transmission: {
								label: data.carAsPaymentVehicle.transmission,
								value: data.carAsPaymentVehicle.transmissionId
							}
					  }
					: {
							brand: undefined,
							model: undefined,
							version: "",
							plate: "",
							plateDate: "",
							vin: "",
							kms: 0,
							color: "",
							fuel: undefined,
							transmission: undefined
					  }
			}
		};

		const agentDiscountDefault = {
			total: data.agentDiscount.total,
			administrativeCosts: {
				selected: data.agentDiscount.administrativeCosts > 0,
				amount: data.agentDiscount.administrativeCosts
			},
			transport: {
				selected: data.agentDiscount.transport > 0,
				amount: data.agentDiscount.transport
			},
			warranty: {
				selected: data.agentDiscount.warranty > 0,
				amount: data.agentDiscount.warranty
			}
		};

		const payAmountsDefault = {
			financingAmount: data.pago_financiado,
			dealershipTPVAmount: data.pago_tpv_tienda,
			virtualTPVAmount: data.pago_tpv_pasarela,
			cashAmount: data.pago_efectivo,
			transferenceAmount: data.pago_transferencia,
			carPayAmount: data.pago_coche_forma_pago
		};

		const defaultValues = {
			client: clientDefault,
			vehicle: vehicleDefault,
			payment: paymentDefault,
			sellInfo: sellInfoDefault,
			agentDiscount: agentDiscountDefault,
			payAmounts: payAmountsDefault,
			observations: data.observaciones
		};

		return defaultValues;
	};

	const editContract = async (formData) => {
		const clientData = {
			nombre: formData.client.name,
			apellido1: formData.client.firstSurname,
			apellido2: formData.client.secondSurname || null,
			DNI: formData.client.dni,
			telefono: formData.client.phone,
			email: formData.client.email,
			provincia: formData.client.province.label,
			id_provincia: formData.client.province.value,
			poblacion: formData.client.town,
			direccion: formData.client.address,
			CP: formData.client.cp
		};

		const vehicleData = {
			marca: formData.vehicle.brand,
			modelo: formData.vehicle.model,
			version: formData.vehicle.version,
			matricula: formData.vehicle.plate,
			plateDate: formData.vehicle.plateDate,
			kilometros: formData.vehicle.km,
			bastidor: formData.vehicle.bastidor,
			color: formData.vehicle.color,
			fuel: formData.vehicle.fuel,
			transmission: formData.vehicle.transmission,
			local: formData.vehicle.dealership.label,
			id_local: formData.vehicle.dealership.value,
			provincia_local: formData.vehicle.dealershipProvince.label,
			id_provincia_local: formData.vehicle.dealershipProvince.value,
			conformidades: formData.vehicle.conformidades || null
		};

		const {
			payment: { type, reservation, entry }
		} = formData;

		const paymentData = {
			paymentTypeId: type.value,
			reservation: reservation.wants.value,
			reservationAmount: reservation.wants.value ? reservation.amount : 0,
			entry: entry.wants.value,
			entryAmount: entry.wants.value ? entry.amount : 0
		};

		const { sellInfo } = formData;
		const { transport, warranty, carAsPayment } = sellInfo;

		const sellData = {
			priceType: Number(sellInfo.priceType),
			administrativeCosts: sellInfo.administrativeCosts,
			agentDiscount: sellInfo.agentDiscount,

			// Transport
			transporte: transport.wants.value,
			importe_transporte: transport.wants.value ? transport.amount : 0,
			transportType: transport.type?.value ? transport.type.value : null,
			expectedDeliveryDate: transport.expectedDeliveryDate || null,

			// Warranty
			warrantyTypeId: warranty.type.value,
			warrantyPrice: warranty.amount,

			// Car as payment
			carAsPayment: carAsPayment.wants.value,
			pago_coche_forma_pago: carAsPayment.wants.value ? carAsPayment.amount : 0,
			carAsPaymentVehicle: carAsPayment.wants.value
				? {
						brand: sellInfo.carAsPayment.vehicle.brand.label,
						brandId: sellInfo.carAsPayment.vehicle.brand.value?.toString(),
						model: sellInfo.carAsPayment.vehicle.model.label,
						modelId: sellInfo.carAsPayment.vehicle.model.value?.toString(),
						version:
							sellInfo.carAsPayment.vehicle.version !== ""
								? sellInfo.carAsPayment.vehicle.version
								: null,
						plate: sellInfo.carAsPayment.vehicle.plate,
						plateDate:
							sellInfo.carAsPayment.vehicle.plateDate !== ""
								? sellInfo.carAsPayment.vehicle.plateDate
								: null,
						vin:
							sellInfo.carAsPayment.vehicle.vin !== "" ? sellInfo.carAsPayment.vehicle.vin : null,
						kms: sellInfo.carAsPayment.vehicle.kms,
						color:
							sellInfo.carAsPayment.vehicle.color !== ""
								? sellInfo.carAsPayment.vehicle.color
								: null,
						fuel: sellInfo.carAsPayment.vehicle.fuel.label,
						fuelId: sellInfo.carAsPayment.vehicle.fuel.value,
						transmission: sellInfo.carAsPayment.vehicle.transmission.label,
						transmissionId: sellInfo.carAsPayment.vehicle.transmission.value
				  }
				: null
		};

		const agentDiscountData = {
			total: formData.agentDiscount.total,
			administrativeCosts: formData.agentDiscount.administrativeCosts.selected
				? formData.agentDiscount.administrativeCosts.amount
				: 0,
			transport: formData.agentDiscount.transport.selected
				? formData.agentDiscount.transport.amount
				: 0,
			warranty: formData.agentDiscount.warranty.selected
				? formData.agentDiscount.warranty.amount
				: 0
		};

		const payAmountData = {
			pago_financiado: formData.payAmounts.financingAmount,
			pago_tpv_tienda: formData.payAmounts.dealershipTPVAmount,
			pago_tpv_pasarela: formData.payAmounts.virtualTPVAmount,
			pago_efectivo: formData.payAmounts.cashAmount,
			pago_transferencia: formData.payAmounts.transferenceAmount
		};

		const datos = {
			...clientData,
			...vehicleData,
			...paymentData,
			...sellData,
			agentDiscount: agentDiscountData,
			...payAmountData,
			empresa_id: data.empresa_id,
			observaciones: formData.observations || null
		};

		try {
			await instance.put(API.UPDATE_SALE_CONTRACT(documentId), {
				data: datos,
				typeContractId: DocumentationType.CONTRATO_VENTA,
				warranty: {
					typeId: warranty.type.value,
					iban_extra: null,
					price: warranty.amount
				}
			});

			await onEdit();
			successToast("Contrato atualizado com sucesso.");
		} catch (err) {
			errorToast(err.response?.data?.message || "Erro ao atualizar o contrato.");
		}
	};

	return (
		<SellContractPT
			mode="edit"
			defaultValues={loadDefaultValues}
			onSubmit={editContract}
			provincesOptions={provincesOptions}
			dealershipsOptions={dealershipsOptions}
			warrantyOptions={warrantyOptions}
			flexicarVehicleId={vehicle_id_stock}
			carAsPaymentBrand={data?.carAsPaymentVehicle?.brand}
		/>
	);
};

export default EditSellContractPT;
