import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import moment from "moment";
import { FaEye, FaRegCheckCircle } from "react-icons/fa";
import {
	MdRefresh,
	MdSchedule,
	MdErrorOutline,
	MdEdit,
	MdDeleteForever,
	MdDirectionsCarFilled,
	MdCarRepair
} from "react-icons/md";

import styles from "./index.module.scss";

import RolType from "@constants/roles";
import DocumentationType from "@constants/documentationType";
import { IoMdDocument } from "react-icons/io";
import { UserContext } from "@contexts/UserContext";
import TableFiles from "@views/Documentation/Components/TableFiles/TableFiles";
import Locale from "@constants/Locale";
import CancelSignComponent from "@components/CancelSignComponent";
import ModalPrintView from "@components/ModalPrintView";
import SellContractModal from "@views/LeadVenta/SellContractModal/SellContractModal";
import SellContractModalPT from "@views/LeadVenta/SellContractModalPT/SellContractModalPT";
import ModalUpdateContract from "@components/ModalUpdateContract";
import UploadComponent from "@components/UploadComponent/UploadComponent";
import { createDraft, putLocal } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { BsPaperclip } from "react-icons/bs";
import ModalTraslado from "@components/ModalUpdateLocalVehicle";
import ModalCalendar from "@components/ModalCalendar";
import ModalWarning from "@components/ModalWarning";
import { getSignUrl, post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import instance from "@services/base";
import ButtonCloseGdv from "@views/Documentation/Components/ButtonCloseGdv/ButtonCloseGdv";
import AdminButton from "@views/Documentation/Components/AdminButton/AdminButton";
import { environment } from "@config/environment.config";


const DocumentationTable = ({
	documentsData,
	setDocumentsData,
	garantia,
	setGarantia,
	changeDocumentState,
	recallGarantia
}) => {
	const { user, rol } = useContext(UserContext);
	const { t, i18n, ready } = useTranslation("myDocumentation");
	const history = useHistory();

	const postventaMatch = matchPath(history.location.pathname, {
		path: "/main/postventa_contratos",
		exact: false,
		strict: false
	});

	const isInPostventaContratos = Boolean(postventaMatch);

	const [docTypesOptions, setDocTypesOptions] = useState(undefined);

	const [toggleDocuments, setToggleDocuments] = useState(null);
	const [dropdownIdx, setDropdownIdx] = useState(-1);
	const [changeLocal, setChangeLocal] = useState();
	const [disabledButton, setDisabledButton] = useState(false);

	const [elementToDelete, setElementToDelete] = useState(null);
	const [warningAlert, setWarningAlert] = useState(false);
	const [warningAlertDocument, setWarningAlertDocument] = useState(false);

	const [modalPrintView, setModalPrintView] = useState();
	const [modalUpdateContract, setModalUpdateContract] = useState();
	const [modalCalendar, setModalCalendar] = useState(null);
	const [cancelSign, setCancelSign] = useState({
		open: false,
		document: null
	});
	const [uploadDocument, setUploadDocument] = useState({
		open: false,
		contractType: null,
		documentId: null,
		usedDocumentTypes: [],
		signStartDate: null
	});

	const docuRoles = [
		RolType.GENERAL_ADMIN,
		RolType.TRADING_AGENT,
		RolType.TRADING_MANAGER,
		RolType.ADMINISTRATION
	];
	const vtcRoles = [RolType.PURCHASING_AGENT];
	const warrantyRoles = [RolType.WARRANTY];
	const adminRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_MANAGER, RolType.ADMINISTRATION];

	const portugueseAccessProtection = i18n.language === Locale.ES;

	const getDocTypesOptions = useCallback(async () => {
		try {
			const response = await instance.get(API.GET_UPLOAD_FILES_DOCUMENT_TYPES());
			setDocTypesOptions(response.data);
		} catch (err) {
			errorToast(t("errors.getDocTypesOptions"));
		}
	}, [t]);

	const changeDropdownIdx = (idx) => {
		toggleDocuments === idx ? setToggleDocuments(null) : setToggleDocuments(idx);
		let selected = -1;
		if (idx !== dropdownIdx) selected = idx;
		setDropdownIdx(selected);
	};

	const changeDealer = async ({ id, type, idDealer }) => {
		try {
			await putLocal({ id, type: type, idDealer: idDealer });
			successToast(`Vehículo trasladado `);
			setChangeLocal({ ...changeLocal, open: false });
		} catch (e) {
			errorToast(<span>{t("errors.errorVehicleTransfer")}</span>);
		}
	};

	const createVehicleDraft = async ({ id, dealer }) => {
		try {
			const response = await createDraft(id);
			if (response.type === 2) {
				errorToast(<span>{t("errors.errorVehiclePublished")}</span>);
			} else if (response.id && !response.new && response.type === 1) {
				if (!vtcRoles.some((r) => rol.includes(r))) {
					history.push(`/main/validacion-vehiculo/${response.id}?tab=datos`);
				} else {
					errorToast(<span>{t("errors.errorVehicleValidation")}</span>);
				}
			} else if (response.id && response.new) {
				if (!vtcRoles.some((r) => rol.includes(r))) {
					successToast("Vehículo en validación creado");
				} else {
					await changeDealer({
						id: response.id,
						type: "draft",
						idDealer: dealer[0].value
					});
					successToast("Vehículo en validación creado");
				}
			}
		} catch (e) {
			const codeError = e?.response?.data?.code;

			if (codeError === "vehicle.exists.chassisNumber") {
				errorToast(<span>{t("errors.errorVehicleInValidationExists")}</span>);
			} else if (codeError === "vehicle.exists.plate") {
				errorToast(<span>{t("errors.errorVehicleInValidationExistsPlate")}</span>);
			} else {
				errorToast(<span>{t("errors.errorCreatingVehicle")}</span>);
			}
		} finally {
			setDisabledButton(false);
		}
	};

	const redirectLead = (id_lead, id_cliente, tipo) => {
		if (tipo === DocumentationType.CONTRATO_VENTA || tipo === DocumentationType.RESERVA)
			history.push(`/main/lead-venta/${id_lead}/${id_cliente}`);

		if (tipo === DocumentationType.COMPRA_DIRECTA || tipo === DocumentationType.GESTION_VENTA)
			history.push(`/main/lead-tasacion/${id_lead}`);
	};

	const findStatus = (document) => {
		let today = moment().format("DD-MM-YYYY");
		let fecha_entrega = moment(document.fecha_entrega).format("DD-MM-YYYY");
		let delivery_status;
		let button_style;
		let disabledButton = false;

		switch (document.status) {
			case 3:
				if (today >= fecha_entrega) {
					delivery_status = "PDTE.CONFIRMAR";
					disabledButton = false;
					button_style = `${styles.grey} ${styles.btnFlexicarMini}`;
				}
				if (today < fecha_entrega) {
					delivery_status = "PDTE.ENTREGA";
					disabledButton = false;
					button_style = `${styles.yellow} ${styles.btnFlexicarMini}`;
				}
				break;
			case 4:
				delivery_status = "ENTREGADO";
				disabledButton = true;
				button_style = `${styles.green} ${styles.btnFlexicarMini} ${styles.disabledBtn}`;
				break;
			case 5:
				delivery_status = "RETIRADO";
				disabledButton = false;
				button_style = `${styles.grey} ${styles.btnFlexicarMini}`;
				break;
			default:
				delivery_status = "ENTREGAR";
				disabledButton = false;
				button_style = `${styles.red} ${styles.btnFlexicarMini}`;
		}

		return (
			<button
				className={
					document.fecha_entrega ? button_style : `${styles.red} ${styles.btnFlexicarMini} `
				}
				disabled={disabledButton}
				onClick={() =>
					setModalCalendar({
						type: "delivery",
						document
					})
				}
			>
				{document.fecha_entrega ? delivery_status : "ENTREGAR"}
			</button>
		);
	};

	const cancelDelete = () => {
		setElementToDelete(null);
		setWarningAlert(false);
		setWarningAlertDocument(false);
	};

	const deleteStartDocument = (id_documento) => {
		setElementToDelete({ id_documento });
		setWarningAlertDocument(true);
	};

	const deleteDocument = async () => {
		try {
			const response = await post(API.DELETE_DOCUMENT, elementToDelete);
			if (!response.error) {
				await changeDocumentState();
				cancelDelete();
				successToast("Elemento borrado con exito");
			} else {
				errorToast(<span>{t("errors.errorDeletingElement")}</span>);
			}
		} catch (e) {
			errorCall(e);
		}
	};

	const deleteStart = (documentId, idDocumentType) => {
		setElementToDelete({ id: documentId, idDocumentType });
		setWarningAlert(true);
	};

	const deleteEnd = async () => {
		try {
			const response = await post(API.DELETE_FILES, elementToDelete);

			if (!response.error) {
				await changeDocumentState();
				cancelDelete();
				successToast("Elemento borrado con exito");
			} else {
				errorToast(<span>{t("errors.errorDeletingElement")}</span>);
			}
		} catch (e) {
			errorCall(e);
		}
	};

	const seeFile = async (file) => {
		if (file.sign_url_id) {
			try {
				const response = await getSignUrl(file.sign_url_id);
				window.open(response.data.url, "_blank", "noopener,noreferrer");
			} catch (err) {
				errorToast(err.response?.data?.message || "Error al abrir el documento");
			}
		} else {
			window.open(file.url, "_blank", "noopener,noreferrer");
		}
	};

	useEffect(() => {
		ready && getDocTypesOptions();
	}, [ready, getDocTypesOptions]);

	return (
		<>
			<div className={styles.margin10}>
				{documentsData.data?.length > 0 ? (
					<div className={styles.smallFont}>
						<div className={styles.tableContainer}>
							<table className="table">
								<thead>
									<tr>
										<th></th>
										<th>{t("type")}</th>
										<th>{t("date")}</th>
										<th>{t("client")}</th>
										<th>{t("vehicle")}</th>
										<th>{t("plate")}</th>
										<th>{t("actions")}</th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									{documentsData.data.map((document, idx) => (
										<Fragment key={`documentsInfo-${document.documentId}-${idx}`}>
											<tr
												style={{
													backgroundColor: idx % 2 === 0 ? "#F7F7F8" : "white"
												}}
											>
												{/* Collapse button */}
												<td
													onClick={() => changeDropdownIdx(idx)}
													className={styles.arrowContainer}
												>
													{(document.files || document.url || document.documentNameCloud) && (
														<div
															key="right"
															className={`${styles.marginPointerArrow} text-primary`}
														>
															{dropdownIdx === idx ? "▼" : "►"}
														</div>
													)}
												</td>

												{/* Type */}
												<td data-label={t("type")}>
													<div className={styles.row}>
														{document.url || document.documentNameCloud ? (
															<div
																title={t("contractUpdoaded")}
																className={styles.okIcon}
															>
																<FaRegCheckCircle size={23} />
															</div>
														) : document.sign_start_date ? (
															<div className={styles.iconsContainer}>
																<div
																	title={t("refresh")}
																	onClick={changeDocumentState}
																	className={styles.refreshIcon}
																>
																	<MdRefresh size={25} />
																</div>
																<div
																	title={t("contractSend")}
																	onClick={() =>
																		setCancelSign({
																			open: true,
																			document
																		})
																	}
																	className={styles.pendingIcon}
																>
																	<MdSchedule size={25} />
																</div>
															</div>
														) : (
															<div
																title={t("attachContract")}
																className={styles.warningIcon}
															>
																<MdErrorOutline size={25} />
															</div>
														)}
														<span className={`ml-2 ${styles.noDecoration}`}>
															{t(`${document.nameType}`)}
														</span>
													</div>
												</td>

												{/* Date */}
												<td data-label={t("date")}>
													<span className={styles.noDecoration}>
														{document.fecha_contrato
															? moment(document.fecha_contrato).format("DD-MM-YYYY")
															: "Fecha no disponible"}
													</span>
												</td>

												{/* Client */}
												<td data-label={t("client")}>
													<span className={styles.noDecoration}>
														{`${document.datos?.nombre} ${document.datos?.apellido1 ? document.datos.apellido1 : ""
															} ${document.datos?.apellido2 ? document.datos.apellido2 : ""}`}
													</span>
												</td>

												{/* Vehicle */}
												<td data-label={t("vehicle")}>
													<span className={styles.noDecoration}>
														{document.tipo === DocumentationType.CONTRATO_VENTA ||
															document.tipo === DocumentationType.COMPRA_DIRECTA ||
															document.tipo === DocumentationType.GESTION_VENTA
															? `${document.datos?.marca ? document.datos?.marca : ""}  ${document.datos?.modelo ? document.datos?.modelo : ""
															}`
															: `${document.datos?.vehiculo ? document.datos?.vehiculo : ""}`}
													</span>
												</td>

												{/* Plate */}
												<td data-label={t("plate")}>
													<span className={styles.noDecoration}>
														{document.datos?.matricula
															? document.datos?.matricula
															: "No disponible"}
													</span>
												</td>

												{/* Actions */}
												<td
													data-label={t("actions")}
													className={`${styles.centerMargin} border-0`}
												>
													<div style={{ display: "flex" }}>
														<span className={`${styles.iconBox}`}>
															{!(document.url || document.documentNameCloud) && (
																<FaEye
																	title={t("see")}
																	size={26}
																	color={"#007bff"}
																	role="button"
																	onClick={() =>
																		setModalPrintView({
																			open: true,
																			contractId: document.documentId,
																			contractSignStartDate: document.sign_start_date,
																			title: document.nameType,
																			tipo: document.tipo
																		})
																	}
																/>
															)}
														</span>

														<span className={styles.iconBox}>
															{!(
																document.url ||
																document.documentNameCloud ||
																document.sign_start_date
															) &&
																!warrantyRoles.some((r) => rol.includes(r)) && (
																	<MdEdit
																		title={t("modify")}
																		size={26}
																		color={"#007bff"}
																		role="button"
																		onClick={() =>
																			setModalUpdateContract({
																				tipo: document.tipo,
																				open: true,
																				document
																			})
																		}
																	/>
																)}
														</span>

														{!warrantyRoles.some((r) => rol.includes(r)) && (
															<span className={styles.bigIconBox}>
																<div
																	onClick={() =>
																		setUploadDocument({
																			open: true,
																			contractType: document.tipo,
																			documentId: document.documentId,
																			usedDocumentTypes: document.files
																				? document.files.map((file) => file.id_document_type)
																				: [],
																			signStartDate: document.sign_start_date
																		})
																	}
																	className={styles.marginPointerBox}
																>
																	<BsPaperclip
																		title={t("attach")}
																		size={26}
																		color={"#007bff"}
																	/>

																	<div
																		style={{
																			width: "22px",
																			textAlign: "center"
																		}}
																		className="btn-flexicar-blue-mini"
																	>
																		{document?.files?.length}
																	</div>
																</div>
															</span>
														)}
													</div>
												</td>

												{/* Buttons */}
												<td>
													<div className={styles.buttonContainer}>
														{document.nameType === "Contrato de Venta" &&
															adminRoles.some((r) => rol.includes(r)) && (
																<button
																	className={
																		document.fecha_facturacion
																			? `${styles.green} ${styles.btnFlexicarMini}`
																			: `${styles.red} ${styles.btnFlexicarMini}`
																	}
																	onClick={() =>
																		setModalCalendar({
																			type: "bill",
																			document,
																			matricula: document.datos?.matricula
																		})
																	}
																>
																	{document.fecha_facturacion ? "FACTURADO" : "FACTURAR"}
																</button>
															)}

														{document.nameType === "Contrato de Venta" &&
															!document.vehicle_id_stock &&
															docuRoles.some((r) => rol.includes(r)) && (
																<button
																	className={
																		document.fecha_entrega
																			? `${styles.green} ${styles.btnFlexicarMini}`
																			: `${styles.red} ${styles.btnFlexicarMini}`
																	}
																	onClick={() =>
																		setModalCalendar({
																			type: "delivery",
																			document
																		})
																	}
																>
																	{document.fecha_entrega ? "ENTREGADO" : "ENTREGAR"}
																</button>
															)}
														{document.nameType === "Contrato de Venta" &&
															document.vehicle_id_stock &&
															docuRoles.some((r) => rol.includes(r)) &&
															findStatus(document)}

														{environment.SHOW_SAGE_BUTTONS &&
															(document?.url || document?.documentNameCloud) &&
															portugueseAccessProtection &&
															isInPostventaContratos ? (
															<>
																<AdminButton
																	adminRoles={adminRoles}
																	task={document?.task}
																	documentId={document.documentId}
																	documentType={document.tipo}
																	changeDocumentState={changeDocumentState}
																/>
																<ButtonCloseGdv
																	documentId={document.documentId}
																	documentType={document.tipo}
																	sellContract={document.cd_contract}
																	recall={changeDocumentState}
																/>
															</>
														) : null}
													</div>
												</td>

												{/* Go to vehicle icon */}
												<td style={{ alignContent: "baseline" }}>
													<span className={styles.iconCarBox}>
														{(document.tipo === DocumentationType.CONTRATO_VENTA ||
															document.tipo === DocumentationType.RESERVA) && (
																<div
																	onClick={() => {
																		history.push(`/main/vehicle/${document.id_vehiculo}?tab=datos`);
																	}}
																	className={styles.marginPointer}
																>
																	<MdDirectionsCarFilled
																		title="Ir al vehículo"
																		size={26}
																		color={"#007bff"}
																	/>
																</div>
															)}
														{(document.tipo === DocumentationType.COMPRA_DIRECTA ||
															document.tipo === DocumentationType.GESTION_VENTA) &&
															(document.url || document.documentNameCloud) &&
															!vtcRoles.some((r) => rol.includes(r)) ? (
															<button
																disabled={disabledButton}
																onClick={async () => {
																	setDisabledButton(true);
																	await createVehicleDraft({ id: document.documentId });
																}}
																className={styles.marginPointer}
															>
																<MdCarRepair
																	title="Crear validación VO"
																	color={"#007bff"}
																	size={28}
																/>
															</button>
														) : null}
														{(document.tipo === DocumentationType.COMPRA_DIRECTA ||
															document.tipo === DocumentationType.GESTION_VENTA) &&
															(document.url || document.documentNameCloud) &&
															vtcRoles.some((r) => rol.includes(r)) ? (
															<button
																disabled={!portugueseAccessProtection}
																onClick={() =>
																	setChangeLocal({
																		open: true,
																		id_document: document.documentId
																	})
																}
																className={styles.marginPointer}
															>
																<MdCarRepair
																	title="Crear validación VO"
																	color={portugueseAccessProtection ? "#007bff" : "#ccc"}
																	size={28}
																/>
															</button>
														) : null}
													</span>
												</td>

												{/*  Go to lead and delete document icons */}
												<td className={`${styles.centerMargin} border-0`}>
													<span className={styles.iconBox}>
														{(document.tipo === DocumentationType.CONTRATO_VENTA ||
															document.tipo === DocumentationType.RESERVA) && (
																<div
																	onClick={() => {
																		redirectLead(
																			document.id_lead,
																			document.id_cliente,
																			document.tipo
																		);
																	}}
																	className={styles.marginPointer}
																>
																	<IoMdDocument
																		title="Ir al lead"
																		size={26}
																		color={"#007bff"}
																	/>
																</div>
															)}
														{(document.tipo === DocumentationType.COMPRA_DIRECTA ||
															document.tipo === DocumentationType.GESTION_VENTA) &&
															document.id_lead != null && (
																<div
																	onClick={() => {
																		redirectLead(
																			document.id_lead,
																			document.id_cliente,
																			document.tipo
																		);
																	}}
																	className={styles.marginPointer}
																>
																	<IoMdDocument
																		title="Ir al lead"
																		size={26}
																		color={"#007bff"}
																	/>
																</div>
															)}
													</span>
													<span className={styles.iconBox}>
														{(rol.includes(RolType.GENERAL_ADMIN) ||
															rol.includes(RolType.ADMINISTRATION) ||
															rol.includes(RolType.TRADING_MANAGER) ||
															rol.includes(RolType.TRADING_MANAGER_PILOT)) && (
																<div
																	onClick={() => deleteStartDocument(document.documentId)}
																	className={styles.marginPointer}
																>
																	<MdDeleteForever
																		title={t("deleteDocument")}
																		size={26}
																		color="red"
																	/>
																</div>
															)}
													</span>
												</td>
											</tr>

											{/* Attached documents */}
											{toggleDocuments === idx && document.files && (
												<tr className={styles.tr_files}>
													<td colSpan={12}>
														<TableFiles
															files={document.files}
															docTypesOptions={docTypesOptions}
															seeFile={seeFile}
															deleteFile={deleteStart}
														/>
													</td>
												</tr>
											)}
										</Fragment>
									))}
								</tbody>
							</table>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center m-5">
						{documentsData.data === null && (
							<>
								<Spinner
									type="grow"
									className="flexicarColor mr-5"
								/>
								<p className="flexicarColor">Cargando documentación...</p>
							</>
						)}

						{documentsData.data?.length === 0 && (
							<p className="flexicarColor">No se encontraron resultados</p>
						)}
					</div>
				)}
			</div>

			<CancelSignComponent
				recall={changeDocumentState}
				setCancelSign={setCancelSign}
				cancelSign={cancelSign}
			/>

			<ModalPrintView
				title={modalPrintView?.title}
				recall={changeDocumentState}
				open={modalPrintView?.open === true}
				close={() => setModalPrintView()}
				contractId={modalPrintView?.contractId}
				contractSignStartDate={modalPrintView?.contractSignStartDate}
				contractType={modalPrintView?.tipo}
			/>

			{uploadDocument.open && (
				<UploadComponent
					uploadDocument={uploadDocument}
					setUploadDocument={setUploadDocument}
					createVehicleDraft={createVehicleDraft}
					vtcRoles={vtcRoles}
					recall={changeDocumentState}
				/>
			)}

			{modalUpdateContract?.open &&
				modalUpdateContract?.document.tipo !== DocumentationType.CONTRATO_VENTA && (
					<ModalUpdateContract
						open={modalUpdateContract?.open === true}
						close={setModalUpdateContract}
						document={modalUpdateContract?.document}
						recall={changeDocumentState}
						recallGarantia={recallGarantia}
						user={user}
					/>
				)}

			{modalUpdateContract?.open &&
				modalUpdateContract?.document.tipo === DocumentationType.CONTRATO_VENTA && (
					<SellContractModal
						isOpen={modalUpdateContract.open}
						setIsOpen={setModalUpdateContract}
						mode="edit"
						document={modalUpdateContract.document}
						recall={changeDocumentState}
						vehicleYear={modalUpdateContract.document.vehicle_year}
						vehicleMonth={modalUpdateContract.document.vehicle_month}
					/>
				)}

			{modalUpdateContract?.open &&
				modalUpdateContract?.document.tipo === DocumentationType.CONTRATO_VENTA &&
				!portugueseAccessProtection && (
					<SellContractModalPT
						isOpen={modalUpdateContract.open}
						setIsOpen={setModalUpdateContract}
						mode="edit"
						document={modalUpdateContract.document}
						recall={changeDocumentState}
						vehicleYear={modalUpdateContract.document.vehicle_year}
						vehicleMonth={modalUpdateContract.document.vehicle_month}
					/>
				)}

			<ModalTraslado
				changeLocal={changeLocal}
				setChangeLocal={setChangeLocal}
				createVehicleDraft={createVehicleDraft}
			/>

			<ModalCalendar
				title={modalCalendar?.type === "bill" ? "Fecha de facturación" : "Fecha de entrega"}
				open={modalCalendar?.type}
				matricula={modalCalendar?.matricula}
				document={modalCalendar?.document}
				type={modalCalendar?.type}
				close={() => setModalCalendar(null)}
				recallDocuments={changeDocumentState}
				setDocumentsInfo={setDocumentsData}
				documentsInfo={documentsData.data}
				findStatus={findStatus}
				garantia={garantia}
				setGarantia={setGarantia}
			/>

			<CancelSignComponent
				recall={changeDocumentState}
				setCancelSign={setCancelSign}
				cancelSign={cancelSign}
			/>

			<ModalWarning
				okButton={deleteDocument}
				cancelButton={cancelDelete}
				open={warningAlertDocument}
			/>

			<ModalWarning
				okButton={deleteEnd}
				cancelButton={cancelDelete}
				open={warningAlert}
			/>
		</>
	);
};

export default DocumentationTable;
