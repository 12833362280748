import React, { useEffect, useState, useRef } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import Locale from "@constants/Locale";
import { LanguageOptions } from "@constants/LanguageOptions";
import GenericModal from "@components/GenericModal";
import Form from "@components/Form/Form";
import FormSelect from "@components/Form/Select/FormSelect";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import RenderTemplateEjs from "@components/RenderTemplateEjs/renderTemplateEjs";
import { errorToast } from "@helpers/toastFunctions";

const ModalCommentpdf = ({
	vehiculo,
	publishVehicle,
	impresion,
	setImpresion,
	type,
	dataTemplate
}) => {
	const { i18n, t } = useTranslation("modalCommentPdf");
	const formRef = useRef();

	const isPortuguese = i18n.language === Locale.PT;
	const defaultValue =
		"Garantia de 18 meses por mútuo acordo. Ao PVP anunciado acresce o valor de 290,00€ (IVA incluído) referente a Despesas Administrativas.";
	const [showTemplate, setShowTemplate] = useState(false);
	const [templateData, setTemplateData] = useState({});
	const [defaultComment, setDefaultComment] = useState({});

	const defaultLanguage = { optionLanguage: isPortuguese ? "pt-PT" : LanguageOptions[0] };

	const onSubmitForm = (formData) => {
		setTemplateData({ ...dataTemplate, ...formData, t });
		setShowTemplate(true);
	};

	const handlePrintClick = () => {
		if ((type === "stock" && !vehiculo.pvp_contado) || (type === "draft" && !vehiculo.priceCash))
			return errorToast(t("errors.printProtectionError"));

		formRef.current.triggerSubmit();
	};

	useEffect(() => {
		if (vehiculo?.comentario_impresion !== undefined) {
			setDefaultComment({ printExtraComment: vehiculo.comentario_impresion });
		} else {
			// Si vehiculo.comentario_impresion es undefined, establecer el valor predeterminado
			setDefaultComment(
				isPortuguese
					? { printExtraComment: defaultValue }
					: { printExtraComment: vehiculo.comentario_impresion }
			);
		}
	}, [isPortuguese, vehiculo?.comentario_impresion]);

	return (
		<GenericModal
			isOpen={impresion}
			setIsOpen={setImpresion}
			headContent={t("modalTitle")}
			bodyContent={
				<>
					<RenderTemplateEjs
						data={templateData}
						templateId={2}
						show={showTemplate}
						setShow={() => {
							setShowTemplate((prev) => !prev);
						}}
					/>

					<Form
						ref={formRef}
						onSubmit={onSubmitForm}
						defaultValues={{ ...defaultLanguage, ...defaultComment }}
					>
						{!isPortuguese && (
							<FormSelect
								name="optionLanguage"
								label={t("language")}
								options={LanguageOptions}
								additionalOnChangeCb={async (opt) => {
									const selectedLanguage = opt.value;
									try {
										const response = await fetch(
											`/locales/${selectedLanguage}/modalCommentPdf.json`
										);
										const translations = await response.json();

										i18n.addResourceBundle(selectedLanguage, "modalCommentPdf", translations);
									} catch (error) {
										console.error(
											`Error al cargar las traducciones para ${selectedLanguage}`,
											error
										);
									}
								}}
							/>
						)}
						<FormTextArea
							name="printExtraComment"
							label={t("comment")}
						/>
					</Form>
				</>
			}
			footerContent={
				<>
					<Button
						type="submit"
						className="btn-flexicar-orange"
						onClick={handlePrintClick}
						style={{ width: "150px" }}
					>
						{t("print")}
					</Button>
					{type === "draft" && (
						<Button
							className="btn-flexicar-orange"
							onClick={() => publishVehicle()}
							style={{ width: "150px" }}
						>
							Publicar
						</Button>
					)}
				</>
			}
		/>
	);
};

export default ModalCommentpdf;
