import { useEffect, useState, useContext } from "react";

import styles from "@styles/views/SellContract/index.module.scss";

import ClientDataPT from "@views/SellContractPT/ClientDataPT/ClientDataPT";
import VehicleDataPT from "@views/SellContractPT/VehicleDataPT/VehicleDataPT";
import PaymentPT from "@views/SellContractPT/PaymentPT/PaymentPT";
import SellDataPT from "@views/SellContractPT/SellDataPT/SellDataPT";
import SellResumePT from "@views/SellContractPT/SellResumePT/SellResumePT";
import ObservationsPT from "@views/SellContractPT/ObservationsPT/ObservationsPT";

import Form from "@components/Form/Form";
import { sellContractSchemaPT } from "@schema/SellContractPT";
import { getStock } from "@services/Vehicle/vehicle.service";
import { IS_SUPER_ADMIN } from "@constants/roles";
import { UserContext } from "@contexts/UserContext";

const SellContractPT = ({
	mode,
	defaultValues,
	onSubmit,
	provincesOptions,
	dealershipsOptions,
	warrantyOptions,
	flexicarVehicleId,
	carAsPaymentBrand
}) => {
	const [areTotalAmountsValid, setAreTotalAmountsValid] = useState(false);
	const [prices, setPrices] = useState({
		buyAndSell: false,
		priceDealerSelling: 0,
		priceDealerSellingOffer: 0,
		priceCash: 0,
		priceFinanced: 0,
		priceOffer: 0
	});

	const { userType } = useContext(UserContext);

	const isSuperAdmin = IS_SUPER_ADMIN(userType);

	useEffect(() => {
		(async () => {
			const stockPrices = await getStock(flexicarVehicleId);

			setPrices({
				buyAndSell: stockPrices.buyAndSell,
				priceDealerSelling: stockPrices.priceDealerSelling,
				priceDealerSellingOffer: stockPrices.priceDealerSellingOffer,
				priceCash: stockPrices.priceCash,
				priceFinanced: stockPrices.priceFinanced,
				priceOffer: stockPrices.priceOffer
			});
		})();
	}, [flexicarVehicleId]);

	return (
		<Form
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			schema={() => sellContractSchemaPT(isSuperAdmin)}
		>
			<ClientDataPT provincesOptions={provincesOptions} />
			<VehicleDataPT dealershipsOptions={dealershipsOptions} />
			<PaymentPT />
			<SellDataPT
				prices={prices}
				carAsPaymentBrand={carAsPaymentBrand}
				warrantyOptions={warrantyOptions} 
				/>
			<SellResumePT
				areTotalAmountsValid={areTotalAmountsValid}
				setAreTotalAmountsValid={setAreTotalAmountsValid}
			/>
			<ObservationsPT />

			<button
				className={`btn btn-flexicar-orange ${styles.createContractButton}`}
				type="submit"
				disabled={!areTotalAmountsValid}
			>
				{
					{
						create: "Criar contrato",
						edit: "Guardar"
					}[mode]
				}
			</button>
		</Form>
	);
};

export default SellContractPT;
