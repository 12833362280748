import { useState } from "react";

import SellContractPT from "@views/SellContractPT/SellContractPT";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { getFilterDealershipsLocals, getFilterProvinces } from "@services/Filtros/filtros";
import { getWarrantiesOptions } from "@services/Warranties/warranties";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { parseDateToISODate } from "@helpers/formatDate";

const CreateSellContractPT = ({ data, onCreate }) => {
	const [dealershipsOptions, setDealershipsOptions] = useState([]);
	const [provincesOptions, setProvincesOptions] = useState([]);
	const [dealershipCompanie, setDealershipCompanie] = useState({});
	const [warrantyOptions, setwarrantyOptions] = useState([]);

	const vehicleId = data.lead.vehicle_id;

	const FIXED_PRICE_ADMINISTRATIVE_COSTS = 290;

	const getCompanieByLocal = async () => {
		try {
			const response = await instance.post(API.GET_EMPRESAS_BY_LOCAL, {
				id_local: data.lead.id_local_for_marketplace
			});
			const companie = response.data[0];
			setDealershipCompanie(companie);
			return companie;
		} catch (err) {
			errorToast(err?.response?.data?.message || "Erro ao obter a empresa das instalações");
		}
	};

	const loadDefaultValues = async () => {
		const [dealerships, provinces, warranties] = await Promise.all([
			getFilterDealershipsLocals(),
			getFilterProvinces(),
			getWarrantiesOptions(vehicleId),
			getCompanieByLocal()
		]);

		setDealershipsOptions(dealerships);
		setProvincesOptions(provinces);
		setwarrantyOptions(warranties);

		const clientDefault = {
			name: data.client.name,
			firstSurname: data.client.firstSurname,
			secondSurname: data.client.secondSurname,
			dni: "",
			phone: data.client.contact.primaryPhone,
			email: data.client.contact.email,
			province: provinces.find((province) => province.value === data.client.address?.province?.id),
			town: data.client.address?.municipality,
			address: "",
			cp: data.client.address?.zipCode
		};

		const vehicleDefault = {
			brand: data.lead.nombre_marca_v,
			model: data.lead.nombre_modelo_v,
			version: data.lead.nombre_version_v,
			plate: data.lead.matricula,
			plateDate: parseDateToISODate(data.lead.fecha_matriculacion),
			km: data.lead.kilometros.replaceAll(".", ""),
			bastidor: data.lead.bastidor,
			color: data.lead.color,
			fuel: data.lead.combustible,
			transmission: data.lead.transmision,
			dealership: dealerships.find((dealership) => dealership.label === data.lead.nombre_local),
			dealershipProvince: undefined,
			conformidades: data.lead.comentario || null
		};

		const paymentDefault = {
			type: undefined,
			reservation: {
				wants: undefined,
				amount: 0
			},
			entry: {
				wants: undefined,
				amount: 0
			}
		};

		const sellInfoDefault = {
			priceType: undefined,
			pvp: 0,
			administrativeCosts: FIXED_PRICE_ADMINISTRATIVE_COSTS,
			transport: {
				wants: undefined,
				amount: 0,
				type: undefined,
				expectedDeliveryDate: ""
			},
			warranty: {
				type: warranties.find(x => x.basic === 1 && x.duration === 18),
				amount: warranties.find(x => x.basic === 1 && x.duration === 18).price
			},
			carAsPayment: {
				wants: undefined,
				amount: 0,
				vehicle: {
					brand: undefined,
					model: undefined,
					version: "",
					plate: "",
					plateDate: "",
					vin: "",
					kms: 0,
					color: "",
					fuel: undefined,
					transmission: undefined
				}
			}
		};

		const agentDiscountDefault = {
			total: 0,
			administrativeCosts: {
				selected: false,
				amount: 0
			},
			transport: {
				selected: false,
				amount: 0
			},
			warranty: {
				selected: false,
				amount: 0
			}
		};

		const payAmountsDefault = {
			financingAmount: 0,
			dealershipTPVAmount: 0,
			virtualTPVAmount: 0,
			cashAmount: 0,
			transferenceAmount: 0,
			carPayAmount: 0
		};

		const defaultValues = {
			client: clientDefault,
			vehicle: vehicleDefault,
			payment: paymentDefault,
			sellInfo: sellInfoDefault,
			agentDiscount: agentDiscountDefault,
			payAmounts: payAmountsDefault,
			observations: null
		};

		return defaultValues;
	};

	const createContract = async (formData) => {
		const clientData = {
			nombre: formData.client.name,
			apellido1: formData.client.firstSurname,
			apellido2: formData.client.secondSurname || null,
			DNI: formData.client.dni,
			telefono: formData.client.phone,
			email: formData.client.email,
			provincia: formData.client.province.label,
			id_provincia: formData.client.province.value,
			poblacion: formData.client.town,
			direccion: formData.client.address,
			CP: formData.client.cp
		};

		const vehicleData = {
			marca: formData.vehicle.brand,
			modelo: formData.vehicle.model,
			version: formData.vehicle.version,
			matricula: formData.vehicle.plate,
			plateDate: formData.vehicle.plateDate,
			kilometros: Number(formData.vehicle.km),
			bastidor: formData.vehicle.bastidor,
			color: formData.vehicle.color,
			fuel: formData.vehicle.fuel,
			transmission: formData.vehicle.transmission,
			local: formData.vehicle.dealership.label,
			id_local: formData.vehicle.dealership.value,
			provincia_local: formData.vehicle.dealershipProvince.label,
			id_provincia_local: formData.vehicle.dealershipProvince.value,
			conformidades: formData.vehicle.conformidades || null
		};

		const {
			payment: { type, reservation, entry }
		} = formData;

		const paymentData = {
			paymentTypeId: type.value,
			reservation: reservation.wants.value,
			reservationAmount: reservation.wants.value ? reservation.amount : 0,
			entry: entry.wants.value,
			entryAmount: entry.wants.value ? entry.amount : 0
		};

		const { sellInfo } = formData;
		const { transport, warranty, carAsPayment } = sellInfo;

		const sellData = {
			priceType: Number(sellInfo.priceType),
			administrativeCosts: sellInfo.administrativeCosts,
			agentDiscount: sellInfo.agentDiscount,

			// Transport
			transporte: transport.wants.value,
			importe_transporte: transport.wants.value ? transport.amount : 0,
			transportType: transport.type?.value ? transport.type.value : null,
			expectedDeliveryDate: transport.expectedDeliveryDate || null,

			// Warranty
			warrantyTypeId: warranty.type.value,
			warrantyPrice: warranty.amount,

			// Car as payment
			carAsPayment: carAsPayment.wants.value,
			pago_coche_forma_pago: carAsPayment.wants.value ? carAsPayment.amount : 0,
			carAsPaymentVehicle: carAsPayment.wants.value
				? {
						brand: sellInfo.carAsPayment.vehicle.brand.label,
						brandId: sellInfo.carAsPayment.vehicle.brand.value?.toString(),
						model: sellInfo.carAsPayment.vehicle.model.label,
						modelId: sellInfo.carAsPayment.vehicle.model.value?.toString(),
						version:
							sellInfo.carAsPayment.vehicle.version !== ""
								? sellInfo.carAsPayment.vehicle.version
								: null,
						plate: sellInfo.carAsPayment.vehicle.plate,
						plateDate:
							sellInfo.carAsPayment.vehicle.plateDate !== ""
								? sellInfo.carAsPayment.vehicle.plateDate
								: null,
						vin:
							sellInfo.carAsPayment.vehicle.vin !== "" ? sellInfo.carAsPayment.vehicle.vin : null,
						kms: sellInfo.carAsPayment.vehicle.kms,
						color:
							sellInfo.carAsPayment.vehicle.color !== ""
								? sellInfo.carAsPayment.vehicle.color
								: null,
						fuel: sellInfo.carAsPayment.vehicle.fuel.label,
						fuelId: sellInfo.carAsPayment.vehicle.fuel.value,
						transmission: sellInfo.carAsPayment.vehicle.transmission.label,
						transmissionId: sellInfo.carAsPayment.vehicle.transmission.value
				  }
				: null
		};

		const agentDiscountData = {
			total: formData.agentDiscount.total,
			administrativeCosts: formData.agentDiscount.administrativeCosts.selected
				? formData.agentDiscount.administrativeCosts.amount
				: 0,
			transport: formData.agentDiscount.transport.selected
				? formData.agentDiscount.transport.amount
				: 0,
			warranty: formData.agentDiscount.warranty.selected
				? formData.agentDiscount.warranty.amount
				: 0
		};

		const payAmountData = {
			pago_financiado: formData.payAmounts.financingAmount,
			pago_tpv_tienda: formData.payAmounts.dealershipTPVAmount,
			pago_tpv_pasarela: formData.payAmounts.virtualTPVAmount,
			pago_efectivo: formData.payAmounts.cashAmount,
			pago_transferencia: formData.payAmounts.transferenceAmount
		};

		const datos = {
			...clientData,
			...vehicleData,
			...paymentData,
			...sellData,
			agentDiscount: agentDiscountData,
			...payAmountData,
			empresa_id: dealershipCompanie.value,
			observaciones: formData.observations || null
		};

		const payload = {
			vehiculo: data.lead.vehicle_id,
			lead: data.lead.id_lead,
			cliente: data.lead.id_cliente_lead,
			warranty: {
				typeId: warranty.type.value,
				iban_extra: null,
				price: warranty.amount
			},
			datos: datos
		};

		try {
			const response = await instance.post(API.CREATE_CONTRACT_VENTA, payload);
			onCreate(response.data.insertId);
			successToast("Contrato criado com sucesso.");
		} catch (err) {
			errorToast("Erro ao criar contrato.");
		}
	};

	return (
		<SellContractPT
			mode="create"
			defaultValues={loadDefaultValues}
			onSubmit={createContract}
			provincesOptions={provincesOptions}
			dealershipsOptions={dealershipsOptions}
			warrantyOptions={warrantyOptions}
			flexicarVehicleId={data.lead.vehicleIdFlexicar}
		/>
	);
};

export default CreateSellContractPT;
